import React from "react";
// TODO: this is stupid. implement json feature like that in outcomes?
import SHORTCARD from "./components/SHORTCARD";
import CPP from "../../assets/techicons/cpp.png";
import Python from "../../assets/techicons/python.png";
import JS from "../../assets/techicons/javascript.png";
import TS from "../../assets/techicons/typescript.png";
import SCSS from "../../assets/techicons/sass.png";
import CSS from "../../assets/techicons/css.png";
import HTML from "../../assets/techicons/html.png";
import CLANG from "../../assets/techicons/llvm.png";
import CMAKE from "../../assets/techicons/cmake.png";
import CONAN from "../../assets/techicons/conan.png";
import REDIS from "../../assets/techicons/redis.svg";
import GOOGLE from "../../assets/techicons/google.png";
import POLARS from "../../assets/techicons/polars.png";
import PANDAS from "../../assets/techicons/pandas.ico";
import NUMPY from "../../assets/techicons/numpy.png";
import NUMBA from "../../assets/techicons/numba.png";
import STATSMODELS from "../../assets/techicons/statsmodels.png";
import TENSORFLOW from "../../assets/techicons/tensorflor.png";
import TORCH from "../../assets/techicons/pytorch-logo.png";
import SKLEARN from "../../assets/techicons/sklearn.png";
import LIGHTGBM from "../../assets/techicons/lightgbm.png";
import GITHUB from "../../assets/githubicon.png";
import ACTION from "../../assets/techicons/actions.png";
import PROJECTS from "../../assets/techicons/play-button.png";
import SLACK from "../../assets/techicons/slack.png";
import CODEDEPLOY from "../../assets/techicons/awscode.png";
import EC2 from "../../assets/techicons/ec2.png";
import TAILWIND from "../../assets/techicons/tailwindcss.png";
import GCP from "../../assets/techicons/gcp.png";
import REACT from "../../assets/techicons/react.png";
import TASKFILE from "../../assets/techicons/taskfile.png";
import VERILOG from "../../assets/techicons/verilog.png";
import GOLANG from "../../assets/techicons/golang.png";
import POETRY from "../../assets/techicons/poetry.png";
import PYBIND from "../../assets/techicons/pybind11.jpeg";
import RABBITMQ from "../../assets/techicons/rabbitmq.png";
import ONNX  from "../../assets/techicons/onnx.png";
import OPTUNA from "../../assets/techicons/optuna.png"
import TERRAFORM from "../../assets/techicons/terraform.png"

export default function TECHNOLOGIES(props) {
  function drift_from_y(start, amount) {
    return start + amount * ((props.scrollPosition - start) / 300).toFixed(2);
  }

  const web_technologies = {
    CSS: CSS,
    JavaScript: JS,
    SCSS: SCSS,
    HTML: HTML,
    TypeScript: TS,
    React: REACT,
    TailwindCSS: TAILWIND,
    GCP: GCP,
  };

  const core_technologies = {
    "C++": CPP,
    "CLang(LLVM)": CLANG,
    CMake: CMAKE,
    Conan: CONAN,
    Redis: REDIS,
    "Google Test / Mock": GOOGLE,
    "Taskfile.dev": TASKFILE,
    Verilog: VERILOG,
    Pybind: PYBIND,
    Poetry: POETRY,
    RabbitMQ: RABBITMQ,
    GoLang: GOLANG
  };

  const pred_technologies = {
    Python: Python,
    "Pola-rs": POLARS,
    Pandas: PANDAS,
    NumPy: NUMPY,
    Numba: NUMBA,
    LightGBM: LIGHTGBM,
    Torch: TORCH,
    SKLearn: SKLEARN,
    Statsmodels: STATSMODELS,
    TensorFlow: TENSORFLOW,
    ONNX: ONNX,
    Optuna: OPTUNA
  };

  const devops_technologies = {
    GitHub: GITHUB,
    "GitHub Actions": ACTION,
    "GitHub Projects": PROJECTS,
    Slack: SLACK,
    "AWS CodeDeploy": CODEDEPLOY,
    "AWS EC2": EC2,
    Terraform: TERRAFORM
  };

  // const hardware_technologies = {
  //   "Ryzen 9 7950X": CPU,
  //   "2 x RTX4090": GPU,
  //   "128GB DDR5 5600": MEM,
  //   "4TB SSD Storage": SSD,
  //   "Ubuntu 22.04": UBUNTU,
  //   "64GB DDR4 3600": RAM,
  //   "16TB HDD": HDD,
  //   "Rocky Linux 9": LINUX,
  // };

  const web_technologies_list = Object.keys(web_technologies).map((tech) => (
    <SHORTCARD photo={web_technologies[tech]} name={tech} />
  ));

  const core_technologies_list = Object.keys(core_technologies).map((tech) => (
    <SHORTCARD photo={core_technologies[tech]} name={tech} />
  ));

  const pred_technologies_list = Object.keys(pred_technologies).map((tech) => (
    <SHORTCARD photo={pred_technologies[tech]} name={tech} />
  ));

  const devops_technologies_list = Object.keys(devops_technologies).map(
    (tech) => <SHORTCARD photo={devops_technologies[tech]} name={tech} />,
  );

  // const hardware_technologies_list = Object.keys(hardware_technologies).map(
  //   (tech) => <SHORTCARD photo={hardware_technologies[tech]} name={tech} />
  // );

  return (
    <div className="relative flex flex-col align-center w-full overflow-hidden text-snow max-w-[2000px] mx-auto">
      <div className="">
        <div className="relative z-30 flex flex-col items-center mt-[150px]">
          <h1 className="font-bold">Technologies</h1>
        </div>
        <div className="relative flex flex-wrap justify-center z-20 mb-[25px]">

          <div className="fixed overflow-hidden w-full h-full bottom-0">
            <img
              className="absolute z-10 w-full lg:mt-0 mt-[100px]"
              style={{ opacity: 100, top: drift_from_y(20, 3) }}
              src={require("../../assets/[H]blob_d.png")}
              alt="blob_d"
            />
            <img
              className="absolute z-20 w-full"
              style={{ opacity: 100, top: drift_from_y(350, -5) }}
              src={require("../../assets/[H]blob_f.png")}
              alt="blob_f"
            />
          </div>
        </div>
      </div>

      <div>
        <div className="tech-team-container">
          <h2 className="tech-team-label">
            Web Technologies
          </h2>
        </div>
        <div className="tech-team-techs">
          {web_technologies_list}
        </div>
      </div>

      <div>
        <div className="tech-team-container">
          <h2 className="tech-team-label">
            Core Teams
          </h2>
        </div>
        <div className="tech-team-techs">
          {core_technologies_list}
        </div>
      </div>

      <div>
        <div className="tech-team-container">
          <h2 className="tech-team-label">
            Prediction Teams
          </h2>
        </div>
        <div className="tech-team-techs">
          {pred_technologies_list}
        </div>
      </div>

      <div>
        <div className="tech-team-container">
          <h2 className="tech-team-label">
            DevOps
          </h2>
        </div>
        <div className="tech-team-techs">
          {devops_technologies_list}
        </div>
      </div>

      {/* <div>
        <div className="relative z-30 flex flex-col items-center mt-[150px] mb-[75px]">
          <h2 className="text-center">Hardware</h2>
        </div>
        <div className="relative flex flex-wrap justify-center z-20 mb-[100px]">
          {hardware_technologies_list}
        </div>
      </div> */}

      <img
        className="fixed z-0 top-0 w-full object-cover h-[700px]"
        src={require("../../assets/[H]bg.png")}
        alt="grid"
      />
      <img
        className="fixed fadein top-0 left-0 rotate-180"
        style={{ height: "2000px" }}
        src={require("../../assets/[p]slide_background.png")}
        alt="gradient"
      />
    </div>
  );
}
