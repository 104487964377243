import EDITBUTTON from "../global/EDITBUTTON";
import Githubicon from "../../assets/githubicon.png";
import LinkediniconWhite from "../../assets/linkedin_icon.png";
import Resumeicon from "../../assets/cv.png";
import LinkIcon from "../../assets/linkicon.png";
import ProfileIcon from "../../assets/profile.png"
import { Formik, Form, } from "formik";
import { updateDoc } from "firebase/firestore";
import { getStorage, ref, uploadBytes } from "firebase/storage";
import { useState } from "react";
import * as Yup from "yup";
import CARDCANVAS from "./profile_components/CARDCANVAS";
import CARDLEFT from "./profile_components/CARDLEFT";
import CARDRIGHT from "./profile_components/CARDRIGHT";
import { FIELD_S } from "./profile_components/INFO_S";
import { FIELDLIST_S } from "./profile_components/INFOLIST_S";
import { FIELD_L } from "./profile_components/INFO_L";
import { FIELDLIST_L } from "./profile_components/INFOLIST_L";
import { DROPDOWN } from "./profile_components/DROPDOWN";
import { HREFFIELD } from "./profile_components/HREF";
import FILEUPLOAD from "./profile_components/FILEUPLOAD";

function EDITPERSONCARD({member, userRef}) {
  const storage = getStorage();

  const [resume_upload, setResume] = useState();
  const [pfp_upload, setPfp] = useState();

  const ProfileSchema = Yup.object().shape({
    first_name: Yup.string().required("Required"),
    last_name: Yup.string().required("Required"),
    pronouns: Yup.string().required("Required"),
    standing: Yup.string().required("Required"),
    gpa: Yup.number("Must be a number")
      .min(0, "GPA Cannot be negative")
      .max(4, "GPA cannot go above 4.0")
      .required("Required"),
    about: Yup.string().required("Required"),
    experience: Yup.string().required("Required"),
    // team: Yup.string().required("Required"),
    career: Yup.string().required("Required"),
    degrees: Yup.array().min(1, "You're working towards a degree, right?"),
  });

  const validateGeneral = (value) => {
    let error;
    if (value === "") {
      error = "Required";
    }
    return error;
  }

  const validateNumber = (value) => {
    let error;
    let regex = /^[0-9]+$/;
    if (value === "") {
      error = "Required";
    } else if (!value.match(regex)) {
      error = "Must be a number";
    }
    return error;
  }

  const submission = async (values) => {
    console.log("here")
    try {
      if (values.pfp != null) {
        let pfpURL = `https://firebasestorage.googleapis.com/v0/b/nuft-site.appspot.com/o/user_pfp%2F${member.uid}`;
        let extension = pfp_upload.name.split(".").pop();
        const pfpRef = ref(storage, `user_pfp/${member.uid}.${extension}`);
        const metadata = { contentType: pfp_upload.type };
        await uploadBytes(pfpRef, pfp_upload, metadata).then(() => {
          pfpURL += `.${extension}?alt=media`;
        });
        await updateDoc(userRef, {
          pfp: pfpURL,
        });
      }
  
      if (values.resume != null) {
        let resumeURL = `https://firebasestorage.googleapis.com/v0/b/nuft-site.appspot.com/o/user_resume%2F${member.uid}`;
        let extension = resume_upload.name.split(".").pop();
        const resumeRef = ref(
          storage,
          `user_resume/${member.uid}.${extension}`,
        );
        const metadataresume = { contentType: resume_upload.type };
        await uploadBytes(resumeRef, resume_upload, metadataresume).then(
          () => {
            resumeURL += `.${extension}?alt=media`;
          },
        );
        await updateDoc(userRef, {
          resume: resumeURL,
        });
      }
  
      await updateDoc(userRef, {
        email: values.email,
        first_name: values.first_name,
        last_name: values.last_name,
        prefname: values.prefname,
        pronouns: values.pronouns,
        type: values.type,
        uid: member.uid,
        linkedin: values.linkedin,
        github: values.github,
        standing: values.standing,
        role: values.role,
        degrees: values.degrees,
        majors: values.majors,
        minors: values.minors,
        certificates: values.certificates,
        gpa: values.gpa,
        about: values.about,
        internships: values.internships,
        experience: values.experience,
        // commitments: values.commitments,
        career:
          values.career === "Other" ? values.other_career : values.career,
        addinfo: values.addinfo,
        interests: values.interests,
      });
      alert("User updated!");
      window.location.reload(false);
    } catch (e) {
      console.log(e.message);
      alert(
        "Error updating user. Please double check that the fields are filled out correctly.",
      );
    }
  }

  return (
    <Formik
      enableReinitialize={true}
      initialValues={{
        first_name: member.first_name,
        last_name: member.last_name,
        prefname: member.prefname,
        pronouns: member.pronouns,
        email: member.email,
        type: member.type,
        linkedin: member.linkedin,
        github: member.github,
        resume: null,
        standing: member.standing,
        degrees: member.degrees,
        majors: member.majors,
        minors: member.minors,
        certificates: [],
        gpa: member.gpa,
        about: member.about,
        internships: member.internships,
        pfp: null,
        role: member.role,
        experience: member.experience,
        // commitments: member.commitments,
        career: member.career,
        addinfo: member.addinfo,
        interests: member.interests,
      }}
      validationSchema={ProfileSchema}
      onSubmit={async (values) => {await submission(values)}}
    >
      {(props) => (
        <Form className="w-full">
          <CARDCANVAS>
            <CARDLEFT member={member}>

              <div>

                <FILEUPLOAD icon={ProfileIcon} link={member.pfp} fieldname={"pfp"} accept={"image/jpeg, image/png"} setState={setPfp} setField={props.setFieldValue}/>

                <HREFFIELD icon={Githubicon} value={props.values.github} fieldname={"github"}/>

                <HREFFIELD icon={LinkediniconWhite} value={props.values.linkedin} fieldname={"linkedin"}/>

                <FILEUPLOAD icon={Resumeicon} link={member.resume} fieldname={"resume"} accept={"application/pdf, .doc, .docx"} setState={setResume} setField={props.setFieldValue}/>
              </div>

              <FIELD_S category={"First Name"} info={member.first_name} fieldname={"first_name"} error={props.errors.first_name} touched={props.touched.first_name}/>

              <FIELD_S category={"Preferred Name"} info={member.prefname} fieldname={"prefname"} error={props.errors.prefname} touched={props.touched.prefname}/>

              <FIELD_S category={"Last Name"} info={member.last_name} fieldname={"last_name"} error={props.errors.last_name} touched={props.touched.last_name}/>

              <FIELD_S category={"Pronouns"} info={member.pronouns} fieldname={"pronouns"} error={props.errors.pronouns} touched={props.touched.pronouns}/>

              <FIELD_S category={"Class Year"} info={member.standing} fieldname={"standing"} error={props.errors.standing} touched={props.touched.standing}/>

              <FIELD_S category={"GPA"} info={member.gpa} fieldname={"gpa"} error={props.errors.standing} touched={props.touched.standing}/>

              <FIELD_S category={"Team"} info={member.role} fieldname={"role"} error={props.errors.role} touched={props.touched.role}/>

              <FIELDLIST_S category={"Degree(s)"} value={props.values.degrees} fieldname={"degrees"} validation={validateGeneral}/>

              <FIELDLIST_S category={"Minor(s)"} value={props.values.minors} fieldname={"minors"} validation={validateGeneral}/>

              <FIELDLIST_S category={"Certificate(s)"} value={props.values.certificates} fieldname={"certificates"} validation={validateGeneral}/>
              
            </CARDLEFT>

            <CARDRIGHT member={member}>
              <FIELD_L category={"About"} info={member.about} fieldname={"about"} error={props.errors.about} touched={props.touched.about}/>

              <FIELD_L category={"Relevant Experience"} info={member.experience} fieldname={"experience"} error={props.errors.experience} touched={props.touched.experience}/>

              <FIELDLIST_L category={"Relevant Internships"} value={props.values.internships} fieldname={"internships"} objFields={["company", "position", "year"]} validation={validateGeneral} color={"rgb(192, 132, 252)"}/>

              <DROPDOWN category={"Desired Career"} info={member.career} value={props.values.career} fieldname={"career"} otherfieldname={"other_career"}
                errors={props.errors.career} touched={props.touched.career} 
                options={[
                  "SWE", "Quant Trading", "Quant Research", "Quant Development", "Other"
                ]}
                {...props}
              />

              <FIELDLIST_S category={"Interests"} value={props.values.interests} fieldname={"interests"} validation={validateGeneral} inline={true}/>

              <FIELD_L category={"Additional Information"} info={member.addinfo} fieldname={"addinfo"} error={props.errors.addinfo} touched={props.touched.addinfo}/>
            </CARDRIGHT>
          </CARDCANVAS>

          <div className="flex flex-row justify-end mt-4">
            <EDITBUTTON
              text={"Save"}
              icon={LinkIcon}
              hovwidth={100}
              type="submit"
            />
          </div>
        </Form>
      )}
    </Formik>
  );
}

export default EDITPERSONCARD