import React from 'react'
import nufticon from "../../../assets/nufticon.png";

function MEMBERBUTTON({setSelected, ...props}) {
  let member = props.member;
  let index = props.index;

  return (
    <button
      className="w-full text-white flex flex-row items-center p-2 relative hover:bg-white hover:bg-opacity-20 duration-200"
      key={index}
      onClick={() => {
        setSelected(member);
      }}
    >
      {
        <img
          className="w-16 h-16 rounded-full object-cover object-center"
          src={member.pfp ? member.pfp : nufticon}
          alt="member-icon"
        />
      }
      <div className="flex flex-col items-start ml-4">
        <div className="text-lg font-semibold">
          {member.first_name + " " + member.last_name}
        </div>

        <div className="text-gray-400">{member.type}</div>
      </div>
    </button>
  );
}

export default MEMBERBUTTON