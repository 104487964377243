import React from 'react'

function CARDRIGHT({children, member}) {
  return (
    <div className="flex-grow p-6 pb-4">
      {children}
    </div>
  )
}

export default CARDRIGHT