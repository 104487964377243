import React, { useState, useEffect, useRef } from "react";
import { confirmAlert } from "react-confirm-alert"; // Import
import "react-confirm-alert/src/react-confirm-alert.css"; // Import css

import { useNavigate } from "react-router-dom";
import { db } from "../../firebase";
import { updateDoc, doc, getDoc, deleteDoc } from "firebase/firestore";
import { useLocation } from "react-router-dom";

import BUTTON from "../../components/global/BUTTON";
import Linkicon from "../../assets/linkicon.png";
import Linkiconreverse from "../../assets/linkiconreverse.png";

import DropdownList from "react-widgets/DropdownList";
import "react-widgets/styles.css";
import "../../Adminedit.css";
import { Form, Formik, FieldArray, Field } from "formik";

/**
 *  @constructor
 */
export default function ADMINEDIT() {
  const [profile, setProfile] = useState([]);
  const [mounted, setMounted] = useState(true);
  const location = useLocation();
  const navigate = useNavigate();
  const usersRef = doc(db, "users", location.state.uid);

  const [email, setEmail] = useState("");
  const [type, setType] = useState("");
  const [first_name, setFirstName] = useState("");
  const [last_name, setLastName] = useState("");
  const [error, setError] = useState("");

  const form = useRef();

  const handleDelete = async () => {
    confirmAlert({
      title: "Confirm to delete",
      message: "Are you sure you want to delete " + location.state.name + "?",
      buttons: [
        {
          label: "Yes",
          onClick: () => {
            try {
              deleteDoc(usersRef);
              alert("User deleted!");
              navigate("/admin");
            } catch (e) {
              setError(e.message);
              console.log(e.message);
              alert("Error deleting user.");
            }
          },
        },
        {
          label: "No",
          onClick: () => {
            "";
          },
        },
      ],
    });
  };

  const onSubmit = async (e) => {
    e.preventDefault();
    setError("");
    try {
      await updateDoc(usersRef, {
        email: email,
        first_name: first_name,
        last_name: last_name,
        type: type,
        uid: profile.uid,
        projects: profile.projects,
        interests: profile.interests,
        prefname: profile.prefname,
        pronouns: profile.pronouns,
        degrees: profile.degrees,
        minors: profile.minors,
        certificates: profile.certificates,
        team: profile.team,
        experience: profile.experience,
        commitments: profile.commitments,
        career: profile.career,
      });
      alert("User updated!");
      window.location.reload(false);
    } catch (e) {
      setError(e.message);
      console.log(e.message);
      alert("Error updating user.");
    }
  };

  useEffect(() => {
    const getProfile = async () => {
      const response = await getDoc(usersRef);
      setProfile(response.data());
      setFirstName(response.data().first_name);
      setLastName(response.data().last_name);
      setEmail(response.data().email);
      setType(response.data().type);
    };
    if (mounted) {
      window.scrollTo(0, 0);
      getProfile();
    }
    return () => {
      setMounted(false);
    };
  }, [usersRef, mounted]);

  return (
    <div
      className="relative flex flex-col align-center w-full overflow-hidden
    bg-gradient-to-br from-royalblue to-black text-snow"
    >
      <div className="relative flex flex-row justify-center z-10 p-20">
        <div className="flex flex-col w-2/3 align-center mt-[80px] text-snow">
          <div className="absolute top-24 left-16 z-50 my-4 py-2 text-white">
            <button onClick={() => navigate("/admin")}>
              <BUTTON text={"Back"} icon={Linkiconreverse} hovwidth={108} />
            </button>
          </div>
          <h1 className="font-bold relative flex flex-row justify-center">
            Editing: {profile.first_name} {profile.last_name}
          </h1>
          <h6 className="relative flex flex-row justify-center">
            Change the fields below to edit the user's profile.
          </h6>
          <Formik
            enableReinitialize={true}
            initialValues={{
              projects: profile.projects ? profile.projects : [],
            }}
            onSubmit={async (values) => {
              setError("");
              try {
                await updateDoc(usersRef, {
                  email: email,
                  first_name: first_name,
                  last_name: last_name,
                  type: type,
                  uid: profile.uid,
                  projects: values.projects,
                });
                alert("User updated!");
                window.location.reload(false);
              } catch (e) {
                setError(e.message);
                console.log(e.message);
                alert("Error updating user.");
              }
            }}
            className="ml-[100px] mt-[20px]"
          >
            {(props) => (
              <Form>
                <label>
                  <h4 className="mt-[20px]">First Name</h4>
                </label>
                <div className="relative">
                  <input
                    type="text"
                    label="First Name"
                    className="block w-[100%] bg-transparent text-[14px] font-snow
                        border-b-2 p-[10px]"
                    style={{
                      animationName: "expand",
                      animationDuration: "500ms",
                    }}
                    onChange={(event) => setFirstName(event.target.value)}
                    value={first_name}
                    placeholder={profile.first_name}
                  />
                  <label>
                    <h4 className="mt-[20px]">Last Name</h4>
                  </label>
                  <input
                    type="text"
                    label="Last Name"
                    className="block w-[100%] bg-transparent text-[14px] font-snow
                        border-b-2 p-[10px]"
                    style={{
                      animationName: "expand",
                      animationDuration: "500ms",
                    }}
                    onChange={(event) => setLastName(event.target.value)}
                    value={last_name}
                    placeholder={profile.last_name}
                  />
                </div>
                <label>
                  <h4 className="mt-[20px]">Email</h4>
                </label>
                <input
                  type="email"
                  label="Email address"
                  className="block w-[100%] bg-transparent text-[14px] font-snow
                        border-b-2 p-[10px]"
                  style={{
                    animationName: "expand",
                    animationDuration: "500ms",
                  }}
                  onChange={(event) => setEmail(event.target.value)}
                  value={email}
                  placeholder={profile.email}
                />
                <label className="flex mt-[20px]">
                  <h4 className="">Role</h4>
                  <h5 className=" mt-[5px]">&nbsp;</h5>
                </label>
                {/* <input
                    type="text"
                    label="User Type"
                    className="block w-[100%] bg-transparent text-[14px] font-snow
                        border-b-2 p-[10px]"
                    style={{ animationName: "expand", animationDuration: "500ms" }}
                    onChange={(event) => setType(event.target.value)}
                    value={type}
                    placeholder={profile.type}
                  /> */}

                <DropdownList
                  className="block w-[100%] bg-transparent text-[14px] mt-2 font-snow"
                  defaultValue={type}
                  value={type}
                  data={["Admin", "Member", "Sponsor", "Applicant"]}
                  onChange={(value) => setType(value)}
                />

                <label className="flex mt-[20px]">
                  <h4 className="">Project</h4>
                  <h5 className=" mt-[5px]">&nbsp;</h5>
                </label>
                <FieldArray name="projects">
                  {(arrayHelpers) => (
                    <div>
                      {props.values.projects.length > 0 &&
                        props.values.projects.map((project, index) => (
                          <div className="flex flex-row">
                            <button
                              type="button"
                              className="secondary m-auto hover:text-red-400 duration-200 pr-2"
                              onClick={() => arrayHelpers.remove(index)}
                            >
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                fill="none"
                                viewBox="0 0 24 24"
                                stroke-width="1.5"
                                stroke="currentColor"
                                class="w-6 h-6"
                              >
                                <path
                                  stroke-linecap="round"
                                  stroke-linejoin="round"
                                  d="M6 18L18 6M6 6l12 12"
                                />
                              </svg>
                            </button>
                            <div
                              className="border border-white rounded-md w-full px-3 py-2 flex flex-row justify-between text-white text-sm mt-2"
                              key={index}
                            >
                              <div className="basis-1/3">
                                <div className="text-md font-bold">
                                  <Field
                                    id={`projects.${index}.name`}
                                    name={`projects.${index}.name`}
                                    placeholder="Project name"
                                    className="profile-field w-full"
                                  />
                                </div>
                                <Field
                                  id={`projects.${index}.languages`}
                                  name={`projects.${index}.languages`}
                                  placeholder="Languages used"
                                  className="profile-field w-full"
                                />
                              </div>

                              <Field
                                id={`projects.${index}.duration`}
                                name={`projects.${index}.duration`}
                                placeholder="Duration"
                                className="profile-field"
                              />
                            </div>
                          </div>
                        ))}
                      <button
                        type="button"
                        className="secondary mt-2 hover:text-green-400 duration-200"
                        onClick={() => {
                          arrayHelpers.push("");
                        }}
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          viewBox="0 0 24 24"
                          stroke-width="1.5"
                          stroke="currentColor"
                          class="w-6 h-6"
                        >
                          <path
                            stroke-linecap="round"
                            stroke-linejoin="round"
                            d="M12 4.5v15m7.5-7.5h-15"
                          />
                        </svg>
                      </button>
                    </div>
                  )}
                </FieldArray>
                <div className="mt-[20px]">
                  <button type="submit">
                    <BUTTON text={"Update"} icon={Linkicon} hovwidth={128} />
                  </button>
                </div>
              </Form>
            )}
          </Formik>
          <div className="relative flex flex-row justify-center font-bold">
            <div className="mt-[20px] text-red-500">
              <button onClick={handleDelete}>
                <BUTTON
                  text={"Delete User (FireStore DB Only)"}
                  icon={Linkicon}
                  hovwidth={380}
                />
              </button>
            </div>
          </div>
        </div>
      </div>
      <img
        className="fixed z-0 top-0 w-full object-cover h-full"
        src={require("../../assets/[H]bg.png")}
        alt="grid"
      />
      <img
        className="fixed fadein top-0 left-0"
        style={{ height: "2000px" }}
        src={require("../../assets/[c]contact_background.png")}
        alt="gradient"
      />
    </div>
  );
}
