import React from 'react'
import DropdownList from "react-widgets/DropdownList";
import { Field } from "formik";

function DROPDOWNINFO({category, info}) {
  return (
    <div className="member-info profile-margins">
      <div className="member-info-label w-[180px]">{category}</div>
      <div className="text-md text-center border-2 w-full p-1 rounded-md">
        {info}
      </div>
    </div>
  )
}

function DROPDOWN({category, info, value, options, fieldname, otherfieldname, errors, touched, ...props}) {
  return (
    <>
      <div className="member-info-big profile-margins">
        <div className="member-info-label ">{category}</div>
        <div className="member-info-line" />
      </div>

      <div className="member-info-margin">
        <DropdownList
          className="block w-[100%] bg-transparent text-[14px] mt-2 font-snow"
          defaultValue={info}
          value={value}
          data={options}
          onChange={(value) => props.setFieldValue(fieldname, value)}
        />
      </div>

      {value === "Other" ? (
        <Field
          id={otherfieldname}
          name={otherfieldname}
          placeholder="Specify other team..."
          className="profile-field hover:text-blue-400 w-full mb-2"
        />
      ) : (
        ""
      )}
    </>
  )
}

export {DROPDOWNINFO, DROPDOWN}