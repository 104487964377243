import React from "react";

export default function HEADER(props) {
  var o;
  var colorVar;
  if (window.location.pathname !== "/") {
    o = 1.0;
  } else {
    o = (props.scrollPosition - window.innerHeight + 100) / 70;
  }
  var prog =
    (props.scrollPosition / (window.document.body.offsetHeight - 1000)) * 100;
  if (prog < 25) {
    colorVar = "#F8F0F0";
  } else if (prog < 50) {
    colorVar = "#FF8080";
  } else if (prog < 75) {
    colorVar = "#ED3D63";
  } else {
    colorVar = "#8D3FD0";
  }
  // console.log(prog); // commented out for performance @TomChoi

  return (
    <div
      className="fixed h-20 px-[5%] z-40
                    flex flex-row w-full justify-between items-center
                    bg-black text-snow drop-shadow-2xl"
      style={{ opacity: o.toFixed(1) }}
    >
      <a href="/" className="flex flex-row items-center">
        <img
          className="object-scale-down h-6 mr-2"
          src={require("../../assets/nufticon.png")}
          alt="icon"
        />
        <h1 className="text-3xl">NUFT</h1>
      </a>
      <div
        id="progress"
        style={{ width: prog + "%", borderColor: colorVar }}
      ></div>
    </div>
  );
}
