import React from "react";
import {
  collection,
  getDocs,
  query,
  where,
  doc,
  updateDoc,
} from "firebase/firestore";
import { db } from "../../firebase";
import { useState, useEffect } from "react";
import Fuse from "fuse.js";
import { confirmAlert } from "react-confirm-alert"; // Import
import emailjs from "@emailjs/browser";
import PERSONCARD from "../../components/member_tracking/PERSONCARD";
import SIDEBAR from "../../components/member_tracking/directory_components/SIDEBAR";
import APPLICANTBUTTON from "./components/APPLICANTBUTTON";

function APPLICANT() {
  const [members, setMembers] = useState([]);
  const [input, setInput] = useState("");
  const [selected, setSelected] = useState({});
  const [currFilter, setCurrFilter] = useState("Pending");
  const [filteredMembers, setFilteredMembers] = useState([]);
  const [showSidePanel, setShowSidePanel] = useState(true);

  const fetchMembers = async (filter) => {
    setCurrFilter(filter);
    let q = query(collection(db, "users"), where("type", "==", "Applicant"));

    await getDocs(q).then((querySnapshot) => {
      const newData = querySnapshot.docs.map((doc) => ({
        ...doc.data(),
        name: doc.data().first_name + " " + doc.data().last_name,
        id: doc.id,
      }));
      setMembers(newData);
      setFilteredMembers(newData.filter((m) => m.status === filter));
    });
  };

  const options = {
    keys: ["name", "team"],
    includeAllMatches: true,
    includeScore: true,
    findAllMatches: true,
    threshold: 0.25,
  };

  const fuse = new Fuse(filteredMembers, options);
  console.log(fuse)

  useEffect(() => {
    fetchMembers("Pending");
  }, []);

  useEffect(() => {
    setFilteredMembers(members.filter((m) => m.status === currFilter));
  }, [currFilter, members])

  // Accepts a member (set status to accepted)
  function handleAccept(member) {
    updateDoc(doc(db, "users", member.id), {
      status: "Accepted",
    });

    setFilteredMembers(filteredMembers.filter((m) => m.uid !== member.uid));

    const newMembers = members.map((obj) => {
      if (obj.uid === member.uid) {
        return { ...obj, status: "Accepted" };
      }
      return obj;
    });

    setMembers(newMembers);
  }

  // Rejects a member (set status to rejected)
  const handleReject = (member) => {
    updateDoc(doc(db, "users", member.id), {
      status: "Denied",
    });

    setFilteredMembers(filteredMembers.filter((m) => m.uid !== member.uid));

    const newMembers = members.map((obj) => {
      if (obj.uid === member.uid) {
        return { ...obj, status: "Denied" };
      }
      return obj;
    });

    setMembers(newMembers);
  };

  const handleRevert = (member) => {
    updateDoc(doc(db, "users", member.id), {
      status: "Pending",
    });

    setFilteredMembers(filteredMembers.filter((m) => m.uid !== member.uid));

    const newMembers = members.map((obj) => {
      if (obj.uid === member.uid) {
        return { ...obj, status: "Pending" };
      }
      return obj;
    });

    setMembers(newMembers);
  }

  const handleRejectAll = async () => {
    confirmAlert({
      title: "Confirm to reject all applicants",
      message:
        "Are you sure you want to reject all currently pending applicants? (think of the children)",
      buttons: [
        {
          label: "Yes",
          onClick: () => {
            try {
              // Loop through array and set everyone to rejected...
              let newMembers = members;
              filteredMembers.forEach((member) => {
                updateDoc(doc(db, "users", member.id), {
                  status: "Limbo",
                });

                newMembers = newMembers.map((obj) => {
                  if (obj.uid === member.uid) {
                    return { ...obj, status: "Limbo" };
                  }
                  return obj;
                });
                let templateParams = {
                  to_name: `${member.first_name} ${member.last_name}`,
                  to_email: member.email,
                };

                emailjs
                  .send(
                    "service_0qabqac",
                    "template_46hsb8q",
                    templateParams,
                    "H1b9p-pcdrDw174_t",
                  )
                  .then((error) => {
                    console.log(error.text);
                  });
              });
              setMembers(newMembers);
              setFilteredMembers([]);
              alert("Applicants cleared. There were no survivors");
            } catch (e) {
              console.log(e);
              alert("An error occurred (they live another day).");
            }
          },
        },
        {
          label: "No",
          onClick: () => {
            "";
          },
        },
      ],
    });
  };

  const handleAcceptAll = () => {
    confirmAlert({
      title: "Confirm to accept all applications",
      message: "Would you like to admit all listed applications?",
      buttons: [
        {
          label: "Yes",
          onClick: () => {
            try {
              // Loop through array and set everyone to rejected...
              let newMembers = members;
              filteredMembers.forEach((member) => {
                console.log(member);
                updateDoc(doc(db, "users", member.id), {
                  status: "Limbo",
                  type: "Member",
                });

                newMembers = newMembers.map((obj) => {
                  if (obj.uid === member.uid) {
                    return { ...obj, status: "Limbo" };
                  }
                  return obj;
                });

                let templateParams = {
                  to_name: `${member.first_name} ${member.last_name}`,
                  to_email: member.email,
                };

                emailjs
                  .send(
                    "service_0qabqac",
                    "template_brgt3xu",
                    templateParams,
                    "H1b9p-pcdrDw174_t",
                  )
                  .then((error) => {
                    console.log(error.text);
                  });
              });

              setMembers(newMembers);
              setFilteredMembers([]);
              alert("Accepted all applicants.");
            } catch (e) {
              console.log(e);
              alert("An error occurred (they live another day).");
            }
          },
        },
        {
          label: "No",
          onClick: () => {
            "";
          },
        },
      ],
    });
  };

  let filterbuttons = ["Pending", "Accepted", "Denied", "Shadow Realm"];

  return (
    <div className="h-screen bg-royalblue flex flex-row">
      <div style={{ 'display': 'flex', 'flex-direction': 'row' }}>
        <SIDEBAR filters={filterbuttons} currFilter={currFilter} setCurrFilter={setCurrFilter} showSidePanel={showSidePanel} setShowSidePanel={setShowSidePanel}/>
        <div  style={{ 'overflow': 'hidden', 'transition': '0.5s', 'width': showSidePanel ? '30rem' : '0rem' }}>
          <div className="flex flex-col h-full bg-[#141852] p-3 pt-24" style={{'width': '30rem'}}>
            <div className="flex text-white items-center">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke-width="1.5"
                stroke="currentColor"
                class="w-16 h-16"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  d="M17.982 18.725A7.488 7.488 0 0012 15.75a7.488 7.488 0 00-5.982 2.975m11.963 0a9 9 0 10-11.963 0m11.963 0A8.966 8.966 0 0112 21a8.966 8.966 0 01-5.982-2.275M15 9.75a3 3 0 11-6 0 3 3 0 016 0z"
                />
              </svg>
              <div className="ml-2">
                <h2>Applicant Directory</h2>
                <div className="text-gray-500">{filteredMembers.length} People</div>
              </div>
              <div className="flex-1 flex justify-end">
                {currFilter === "Denied" ? (
                  <button
                    className="mr-4 text-red-500 hover:text-red-800 duration-200"
                    onClick={handleRejectAll}
                  >
                    Reject All
                  </button>
                ) : (
                  ""
                )}

                {currFilter === "Accepted" ? (
                  <button
                    className="mr-4 text-green-500 hover:text-green-800 duration-200"
                    onClick={handleAcceptAll}
                  >
                    Accept All
                  </button>
                ) : (
                  ""
                )}
              </div>
            </div>

            <input
              className="w-full h-10 mt-4 rounded-sm bg-white bg-opacity-20 outline-none text-white p-2"
              value={input}
              onChange={(e) => setInput(e.target.value)}
              placeholder="Search"
            />

            <div className="flex-1 mt-2 w-full overflow-y-auto overflow-x-hidden flex flex-col items-start">
              {input
                ? fuse.search(input).map((member, i) => {
                  return <APPLICANTBUTTON member={member.item} index={i} setSelected={setSelected} handleAccept={handleAccept} handleReject={handleReject} handleRevert={handleRevert}/>;
                })
                : filteredMembers.map((member, i) => {
                  return <APPLICANTBUTTON member={member} index={i} setSelected={setSelected} handleAccept={handleAccept} handleReject={handleReject} handleRevert={handleRevert}/>;
                })}
            </div>
          </div>
        </div>
      </div>
      <div className="flex flex-1 h-full bg-[#0a0d2b] p-6 pt-24" style={{'width': '-webkit-fill-available'}}>
        {Object.keys(selected).length !== 0 ? (
          <PERSONCARD member={selected} />
        ) : (
          <h2 className="w-full h-full flex justify-center items-center text-white">
            Select a member to get started...
          </h2>
        )}
      </div>
    </div>
  );
}

export default APPLICANT;
