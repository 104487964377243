import React from 'react'
import BACKGROUND from "../../../assets/[H]gradient.png";

function CARDCANVAS({children, member}) {
  return (
    <div className="profile-parent-card ">
      <img
          className="w-full h-1/5 object-cover object-center"
          src={BACKGROUND}
          alt="member-icon"
      />

      <div className="flex flex-row h-4/5 flex-nowrap">
        {children}
      </div>
    </div>
  )
}

export default CARDCANVAS