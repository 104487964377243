import React from 'react'
import nufticon from "../../../assets/nufticon.png";

function APPLICANTBUTTON({handleAccept, handleReject, handleRevert, setSelected, ...props}) {
  let member = props.member;
    let index = props.index;

    if (member.type === "Applicant") {
      return (
        <div className="relative w-full">
          <button
            className={`w-full text-white flex flex-row items-center p-2 relative group`}
            key={index}
            onClick={() => {
              setSelected(member);
            }}
          >
            {
              <img
                className=" h-16 rounded-full object-cover object-center"
                src={member.pfp ? member.pfp : nufticon}
                alt="member-icon"
              />
            }
            <div className="absolute inset-0 bg-transparent group-hover:bg-white group-hover:bg-opacity-20 transition duration-200"></div>

            <div className="flex flex-col items-start ml-4">
              <div className="text-lg font-semibold">
                {member.first_name + " " + member.last_name}
              </div>
              <div className="text-gray-400">{member.type}</div>
            </div>
          </button>

          <div className="absolute h-full right-0 top-0 flex flex-row justify-end pr-4 items-center">
            {member.status === "Denied"  || member.status === "Accepted" ? (
                <button
                  className={`applicant-buttons hover:text-yellow-500 duration-200 mr-4`}
                  title="Revert"
                  onClick={() => handleRevert(member)}
                >
                  <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
                    <path stroke-linecap="round" stroke-linejoin="round" d="M16.023 9.348h4.992v-.001M2.985 19.644v-4.992m0 0h4.992m-4.993 0 3.181 3.183a8.25 8.25 0 0 0 13.803-3.7M4.031 9.865a8.25 8.25 0 0 1 13.803-3.7l3.181 3.182m0-4.991v4.99" />
                  </svg>
                </button>
            ) : null}
            {member.status !== "Denied" && member.status !== "Limbo" ? (
              <button
                className={`applicant-buttons hover:text-red-500 duration-200`}
                title="Reject"
                onClick={() => handleReject(member)}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke-width="1.5"
                  stroke="currentColor"
                  class="w-6 h-6"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    d="M6 18L18 6M6 6l12 12"
                  />
                </svg>
              </button>
            ) : null}
            {member.status !== "Accepted" && member.status !== "Limbo" ? (
              <button
                className="applicant-buttons ml-4 hover:text-green-500 duration-200"
                title="Accept"
                onClick={() => handleAccept(member)}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke-width="1.5"
                  stroke="currentColor"
                  class="w-6 h-6"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    d="M4.5 12.75l6 6 9-13.5"
                  />
                </svg>
              </button>
            ) : null}
          </div>
        </div>
      );
    }
}

export default APPLICANTBUTTON