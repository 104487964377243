import React from "react";
import Githubicon from "../../assets/githubicon.png";
import Linkicon from "../../assets/linkicon.png";
import Emailicon from "../../assets/[H]email_icon.png";
import Instaicon from "../../assets/[H]instagram_icon.png";
import Linkedinicon from "../../assets/[H]linkedin_icon.png";
import LinkediniconWhite from "../../assets/linkedin_icon.png";
import FAQ from "./components/FAQ";

import BUTTON from "../../components/global/BUTTON";
import DARKBUTTON from "../../components/global/DARKBUTTON";
import SPONSOR from "./components/SPONSOR";

export default function HOME(props) {
  function fade_from_y(start) {
    var calc = (props.scrollPosition - start) / 3;
    return calc.toFixed(2) + "%";
  }

  function drift_from_y(start, amount) {
    return start + amount * ((props.scrollPosition - start) / 300).toFixed(2);
  }

  return (
    <div className="w-full flex justify-center items-center">
      <div className="w-full">
        {/* TITLE PAGE */}
        <div
          className="relative z-30 h-screen flex flex-col justify-center
                      lg:pl-72 md:pl-40 sm:pl-20 pl-10 pt-20 pr-10
                      text-snow max-w-[2000px] mx-auto"
        >
          <div className="flex flex-row">
            <div className="flex flex-col relative z-30">
              <img
                className="object-scale-down
                            lg:w-28 w-20"
                src={require("../../assets/nufticon.png")}
                alt="icon"
              />
              <h1 className="my-4 -ml-1 font-bold" style={{ fontSize: "60px" }}>
                NU FINTECH
              </h1>
              <h4 className="roboto mb-5">
                Northwestern University’s premier <br /> quant finance and
                software <br /> development club
              </h4>
              <div className="my-4">
                <BUTTON
                  text={"Github"}
                  icon={Githubicon}
                  hovwidth={115}
                  link={"https://github.com/northwesternfintech"}
                />
              </div>
              <BUTTON
                text={"LinkedIn"}
                icon={LinkediniconWhite}
                hovwidth={140}
                link={"https://www.linkedin.com/company/northwesternfintech/"}
              />
              <div className="inline-block montserrat text-sm mt-4">
                <BUTTON
                  text={"Login"}
                  icon={Linkicon}
                  hovwidth={105}
                  link={"/login"}
                />
              </div>
            </div>
            <img
              className="fadein absolute object-contain z-20 right-0 mr-[15vw] mt-[10vw] drop-shadow-2xl
                          lg:w-[30%] hidden lg:flex
                          opacity-50 md:opacity-75 lg:opacity-100"
              src={require("../../assets/[H]typewriting.gif")}
              alt="typing"
            />
            <div
              className="absolute z-10 right-0 h-[800px] -mt-36
                          lg:w-[60%] md:w-5/6 sm:w-full w-full"
            >
              <img
                className="absolute lg:w-[70%] md:w-[80%] sm:w-[90%] w-[90%] opacity-50 md:opacity-75 lg:opacity-100 object-cover"
                style={{ left: "10%", top: drift_from_y(100, -10) }}
                src={require("../../assets/[H]blob_c_1.png")}
                alt="blob_c"
              />
              <img
                className="absolute lg:w-[10%] md:w-[15%] sm:w-[20%] w-[20%] object-cover"
                style={{ right: "10%", top: drift_from_y(600, -50) }}
                src={require("../../assets/[H]blob_c_2.png")}
                alt="blob_c"
              />
              <img
                className="absolute lg:w-[20%] md:w-[25%] sm:w-[30%] w-[30%] object-cover"
                style={{ top: drift_from_y(0, 50) }}
                src={require("../../assets/[H]blob_c_3.png")}
                alt="blob_c"
              />
            </div>
          </div>
          {/* <img
            className="fixed z-10 left-0 lg:w-[5%] w-[15%]"
            style={{ top: drift_from_y(500, -100), opacity: fade_from_y(150) }}
            src={require("../assets/[H]blob_b.png")}
            alt="blob_b"
          /> */}
        </div>
        {/* OUR MISSION */}
        <div className="flex flex-col">
          <div
            className="relative z-30 flex flex-col justify-center
                        text-snow items-center max-w-[2000px] mx-auto"
          >
            <h1 className="font-semibold" style={{ marginTop: "300px" }}>
              Our Mission
            </h1>
            <h4
              className="text-center roboto w-2/3 mt-10 mb-10 w-[80%] md:w-[60%]
            "
            >
              At NUFT, we aim to improve the quality of the Fintech and Quant
              education at Northwestern, as well as to provide an excellent
              pre-professional space for students to engage and discuss. We seek
              to help students prepare for some of the most competitive roles in
              industry by providing experiences and projects that would be
              impossible for an individual.
            </h4>
            <BUTTON
              text={"Apply Now"}
              icon={Linkicon}
              hovwidth={160}
              link={"/application"}
            />
            <h1 className="font-semibold" style={{ marginTop: "300px" }}>
              What We Do
            </h1>
            <h4 className="text-center roboto w-1/2 m-10 w-[70%] md:w-[40%]">
              We pursue our mission through two different approaches:
              <br />
              <br />
              First is the technical, project-based side where we build a
              simulation quant firm from scratch.
              <br />
              <br />
              Second is the pre-professional side, which includes networking
              events, interview preparation, and more.
            </h4>
            <BUTTON
              text={"Projects"}
              icon={Linkicon}
              hovwidth={130}
              link={"/projects"}
            />
          </div>
          <div
            className="relative overflow-hidden w-full z-10
                        md:h-[800px] h-[115vw] lg:h-[80vw] md:h-[90vw] sm:h-[90vw] -mt-[60vw] sm:-mt-[50vw] lg:-mt-[45vw]
                        w-screen"
          >
            <img
              className="absolute z-20 lg:w-[40%] md:w-[45%] sm:w[50%] w-[60%]
                right-0"
              style={{
                top: drift_from_y(-100, 35),
                opacity: fade_from_y(1200),
              }}
              src={require("../../assets/[H]blob_e.png")}
              alt="blob_e"
            />
            <img
              className="absolute z-20 w-full"
              style={{ opacity: fade_from_y(1100), top: drift_from_y(0, 15) }}
              src={require("../../assets/[H]blob_d.png")}
              alt="blob_d"
            />
            <img
              className="absolute z-20 w-full lg:-bottom-[35vw] md:-bottom[20vw] sm:-bottom-[30vw] -bottom-[10vw]"
              src={require("../../assets/[H]blob_f.png")}
              alt="blob_f"
            />
          </div>
        </div>
        <div
          className="relative z-30 flex flex-col justify-center
                      text-royalblue bg-snow items-center"
        >
          <h1 className="font-semibold">Our Sponsors</h1>
          <h4 className="text-center roboto w-1/2 m-10 max-w-[2000px] mx-auto">
            Here are the companies that make our work in NUFT possible:
          </h4>
          {/* <img
            className="w-[60%] relative z-30 max-w-[2000px] mx-auto"
            src={require("../assets/outcomes.png")}
            alt="outcomes"
          /> */}
          <SPONSOR />
          <FAQ />
          <div className="relative z-30 flex flex-col items-center justify-center">
            <a
              id="custom"
              className="flex flex-col justify-center items-center z-10"
              href="/contact"
              style={{ marginTop: "150px" }}
            >
              <a id="custom_button" href="/contact">
                <img
                  className="m-2 flex-none"
                  src={require("../../assets/[H]linkicon_b.png")}
                  alt="icon"
                />
              </a>
              <h1 id="custom_text" className="font-semibold">
                Get In Touch
              </h1>
            </a>
            <p className="text-center roboto">
              also contact us through...
              <br />
            </p>
            <div
              className="relative z-10 w-full flex sm:flex-row flex-col justify-center items-center"
              style={{ marginBottom: "350px" }}
            >
              <div className="m-2 sm:m-5">
                <DARKBUTTON
                  text={"Linkedin"}
                  icon={Linkedinicon}
                  hovwidth={140}
                  link={"https://www.linkedin.com/company/northwesternfintech/"}
                />
              </div>
              <div className="m-2 sm:m-5">
                <DARKBUTTON
                  text={"Email"}
                  icon={Emailicon}
                  hovwidth={110}
                  link={"mailto:nuft@u.northwestern.edu"}
                />
              </div>
              <div className="m-2 sm:m-5">
                <DARKBUTTON
                  text={"Instagram"}
                  icon={Instaicon}
                  hovwidth={155}
                  link={"https://www.instagram.com/nufintech/"}
                />
              </div>
            </div>

          </div>
        </div>
      </div>
      <img
        className="fixed z-0 top-0 w-full object-cover h-screen"
        src={require("../../assets/[H]bg.png")}
        alt="grid"
      />
      <div>
        {/* <img
          className="absolute z-10 top-0 right-0 object-scale-down object-right-top
                        lg:w-7/12 md:w-8/12 w-10/12"
          src={require("../assets/[H]blob_a.png")}
          alt="blob_a"
        /> */}
        <img
          className="absolute fadein z-0 top-0 left-0"
          style={{ width: "100%", height: 3000 }}
          src={require("../../assets/[H]gradient.png")}
          alt="gradient"
        />
      </div>
    </div>
  );
}
