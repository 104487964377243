import React, { useState } from "react";
import { useSpring, animated } from "react-spring";

export default function BUTTON(props) {
  const [hovered, setHovered] = useState(false);

  const springstyle = useSpring({
    width: hovered ? props.hovwidth : 28,
    background: hovered ? "rgba(22, 26, 88, 0.25)" : "rgba(22, 26, 88, 0)",
    height: 28,
  });

  return (
    <div>
      <a
        className="inline-flex justify-start items-center"
        onMouseLeave={() => setHovered(false)}
        onMouseEnter={() => setHovered(true)}
        href={props.link}
        target="_blank"
        rel="noreferrer"
      >
        <img
          className="object-scale-down h-4 ml-1.5 flex-none"
          src={props.icon}
          alt="icon"
        />
        <div className="ml-4 text-xl text-royalblue">{props.text}</div>
        <div className="absolute">
          <animated.div
            className="bg-royalblue border border-royalblue rounded-full"
            style={springstyle}
          />
        </div>
      </a>
    </div>
  );
}
