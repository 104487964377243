import React from "react";

export default function FOOTER() {
  return (
    <div className="z-40 roboto text-snow bg-black p-24">
      <h2 className="text-center">© NU FinTech All rights reserved.</h2>
      <div className="text-center text-ss">
        Developed in-house by the NUFT web team
      </div>
      <div className="flex justify-center text-s text-blue-200">
        <a href="https://www.flaticon.com/" title="trading icons">
          Icons from Flaticon
        </a>
      </div>
    </div>
  );
}
