import React from 'react'
import DRW from "../../../assets/outcomes/logos/DRW_Holdings.png"
import IMC from "../../../assets/outcomes/logos/2560px-IMC_Logo.svg.png"

import { useInView, animated, useSpring, useTrail } from "react-spring"

const RANK = {
  "Gold": 1,
  "Silver": 2,
  "Bronze": 3
}

function SPONSORCARD({sponsor, index}){

  const crown = (rank) => {
    switch(rank){
      case RANK.Gold:
        return (<img className='w-8 h-8' src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADAAAAAwCAYAAABXAvmHAAAAAXNSR0IArs4c6QAAAV5JREFUaEPtWW0OgyAMLTcbJ1NPhjdjY0HjGFAsjyzO8tPZx/ugLqGGLr7MxfmTCvh1gsUEvKPZWJp7CAaMUI/AKWHUBPjX3ot0c+/oQUQuGmCNpVViRjRhMjZ/3DkBYU+RCO8oGLCvEoGaqI18TDHLtUXAaRHevZ0PCRzXaizZ1hSO5BECmkWkGyeEm9LMYfQcoSOHKgGG/IYjwkAJKCaRNC13UrJNXTMAKSArIm1aTkFKiEsPLeBDRKFpOQ17U3PkUU2cI7TEhxPHtvB7c/2IBIScZWUqQOYbrkoTwHkpQ9IEZL7hqjQBnJcyJE1A5huuShPAeSlD0gRkvuGq7pcAzruxSHq5O9ZfHv1/Ezh7TcJ71ffG/b5CmkDfifmqvuURyt3xg31thiveaNcGHMcRUfNOI16sTXfY/4GWi9cRpCNmmKsF94vzNVbAQHIQaBUAsbEDRBPoMA9SevkEnsfdrzHIdSqXAAAAAElFTkSuQmCC"/>);
      case RANK.Silver:
        return (<img className='w-8 h-8' src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADAAAAAwCAYAAABXAvmHAAAAAXNSR0IArs4c6QAAAV1JREFUaEPtWksOhCAMlZvpncStuhXvNN6skybjxBBpkXY0jnVNH+9DMam66uaPuzn/ygRcnWAyga7rhmmaBglBxMB6DZwURlKA9x6cc2Pp5m3b1s65FwoAgGae56XEDDQBAPoQwi5XUgBuWCoCDdgSThGgRK3kcU2xgBIR3nt0vo7ILSGEJjeFLXmxgCMi4o23hHPT3MMQJbCS4AhQ5KUYKgKoJLZNyx2TVFNTBqgJSImIm5YTERPi0lMVEItINC2n4dvUHHmVJt5jgz3xued7jq20Xj2BEsKSGhMgcU+j1hLQcFGCYQlI3NOotQQ0XJRgWAIS9zRqLQENFyUYloDEPY3a5yWg4doZGDbcPcNlao//TeDomOTXSTzvFrIElM/UI4/Q3oxf2dc8OGoqnrxGj0yb82iUr6K+7rDvgZzBazk1tnIBgJH6vsYKYLe4eIEJuDgA+9Xg6gDun8AbdBQTQJvwwcEAAAAASUVORK5CYII="/>);
      case RANK.Bronze:
        return (<img className='w-8 h-8' src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADAAAAAwCAYAAABXAvmHAAAAAXNSR0IArs4c6QAAAXJJREFUaEPtWssNwjAMjWEWYI0yBHACsUnpJghOwBB0DWAXgiKEKFVip7GhKnXP8cv7xKnkFkzHH+g4f6MC2k4wmMDlsNpMFrsNh6DDcPUSOCGMoIDrcWWtNUXq5rfTOrP2fnYCAAbT0WxbppjhTAAw+Xi+83JFBbgNU0U4A6qEQwQwUS/ybk2ygBQR1+PybAxkn+RsOZ7vp7EpVMmzBTQRUd+4Sjg2TR8GK4EXCYoARp6LISIAS6LatNQxCTU1ZoCYgJCIetNSIuqEqPREBdRF+JuWkvBuaoq8SBP76LieeN7zJqfocuvFE0ghzKlRARz3JGo1AQkXORiaAMc9iVpNQMJFDoYmwHFPolYTkHCRg6EJcNyTqO1fAhKu/QJDh7u/cBnb438TaDom+XYS/buFNAHhM9XHI+Sb8QvbGgmHTcWD12iTaXMkj+Rl2Ncd8j0QM3hNZkYW2hJgWGDf10gB5B4tL1ABLQegvxq0HUD3E3gACU4JQCacVbQAAAAASUVORK5CYII="/>);
    }
  }

  const [ref, isInView] = useInView({once: true})
  const styles = useSpring({
    opacity: isInView ? 1 : 0,
    y: isInView ? 0 : -50,
    delay: 200 + index * 100,
  })
  return (
    <animated.div className='flex flex-col items-center' ref={ref} style={styles}>
      {crown(sponsor.rank)}
      {/* <span className='font-semibold text-lg tracking-wider'>{sponsor.name}</span> */}
      <img className='h-16 mt-4' src={sponsor.logo} alt='logo'/>
    </animated.div>
  )
}

function SPONSOR() {
  const sponsors = [
    {
      name: "DRW",
      logo: DRW,
      rank: RANK.Silver
    },
    {
      name: "IMC",
      logo: IMC,
      rank: RANK.Silver
    },
  ]
  return (
    <div className='flex flex-row justify-around w-[90%]'>
      {sponsors.map((sponsor, index) => <SPONSORCARD sponsor={sponsor} index={index}/>)}
    </div>
  )
}

export default SPONSOR