import "../../App.css";
import SIGNIN from "./SIGNIN.js";

export default function LOGIN(props) {
  return (
    <div
      className="relative flex flex-col justify-center items-center w-full h-screen overflow-hidden
    bg-gradient-to-br from-royalred to-black text-snow"
    >
      <div className="relative flex flex-row justify-between z-10 p-20 max-w-[2000px] mx-auto">
        <div className="flex flex-col w-1/2 mt-[80px] text-snow">
          <h1 className="font-bold">LOG IN</h1>
          <h6>
            Log in to your NUFT account to access the application portal,
            customize your profile, and view important information.
          </h6>
        </div>
        <SIGNIN />
      </div>
      <img
        className="fixed z-0 top-0 w-full object-cover h-full"
        src={require("../../assets/[H]bg.png")}
        alt="grid"
      />
      <img
        className="fixed fadein top-0 left-0 w-full"
        style={{ height: "1000px" }}
        src={require("../../assets/[H]blob_g.png")}
        alt="gradient"
      />
    </div>
  );
}
