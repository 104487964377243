import React from "react";
// import { useSpring, animated } from 'react-spring'
import Settingsicon from "../../assets/settingsicon.png";
import EDITBUTTON from "../global/EDITBUTTON";
import { useNavigate } from "react-router-dom";

export default function ADMINCARD(props) {
  const navigate = useNavigate();
  const handleEdit = () => {
    navigate("/adminedit", {
      state: { uid: props.uid, name: props.name },
    });
  };
  if (props.role === "Admin") {
    return (
      <div
        className="z-20 bg-black rounded-xl overflow-hidden shadow-lg m-4 drop-shadow-2xl
                        w-screen"
      >
        <div className="px-6 py-2 flex flex-col items-center">
          <div className="font-bold text-xl">{props.name}</div>
          <div className="text-lg m-1 text-gray-70">Email: {props.email}</div>
          <div className="animated-divider"></div>
          <p className="card-text-height text-base text-red-500">
            {" "}
            Role: {props.role}
          </p>
        </div>
        <div
          id="links"
          className="flex flex-row justify-around px-6 pt-2 pb-2 mb-2"
        >
          <button onClick={handleEdit}>
            <EDITBUTTON text={"Edit"} icon={Settingsicon} hovwidth={95} />
          </button>
        </div>
      </div>
    );
  } else if (props.role === "Member") {
    return (
      <div
        className="z-20 bg-black rounded-xl overflow-hidden shadow-lg m-4 drop-shadow-2xl
                                w-screen"
      >
        <div className="px-6 py-2 flex flex-col items-center">
          <div className="font-bold text-xl">{props.name}</div>
          <div className="text-lg m-1 text-gray-70">Email: {props.email}</div>
          <div className="animated-divider"></div>
          <p className="card-text-height text-base text-emerald-500">
            {" "}
            Role: {props.role}
          </p>
        </div>
        <div
          id="links"
          className="flex flex-row justify-around px-6 mr-4 pt-2 pb-2 mb-2"
        >
          <button onClick={handleEdit}>
            <EDITBUTTON text={"Edit"} icon={Settingsicon} hovwidth={95} />
          </button>
        </div>
      </div>
    );
  } else {
    return (
      <div
        className="z-20 bg-black rounded-xl overflow-hidden shadow-lg m-4 drop-shadow-2xl
                            w-screen"
      >
        <div className="px-6 py-2 flex flex-col items-center">
          <div className="font-bold text-xl">{props.name}</div>
          <div className="text-lg m-1 text-gray-70">Email: {props.email}</div>
          <div className="animated-divider"></div>
          <p className="card-text-height text-base text-blue-500">
            {" "}
            Role: {props.role}
          </p>
        </div>
        <div
          id="links"
          className="flex flex-row justify-around px-6 pt-2 pb-2 mb-2"
        >
          <button onClick={handleEdit}>
            <EDITBUTTON text={"Edit"} icon={Settingsicon} hovwidth={95} />
          </button>
        </div>
      </div>
    );
  }
}
