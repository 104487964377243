import React from "react";
import CARD from "./components/CARD";
import ILYA from "../../assets/teamPhotos/ilyaPhoto.png";
import JERRY from "../../assets/teamPhotos/jerrycao.jpeg";
import JIALE from "../../assets/teamPhotos/jialechen.jpeg";
import RYAN from "../../assets/teamPhotos/ryanwong.jpeg";
import STEVE from "../../assets/teamPhotos/steveewald.jpeg";
import ZHAORAN from "../../assets/teamPhotos/zhaoranwang.jpeg";
import ETHAN from "../../assets/teamPhotos/ethanhavemann.jpeg";
import HONG from "../../assets/teamPhotos/hongzhou.jpeg";
import AIDAN from "../../assets/teamPhotos/aidanvillasenor.jpg";
// import DAVID from "../assets/teamPhotos/davidpark.jpeg";
import JAY from "../../assets/teamPhotos/jaypark.jpg";

export default function LEADERSHIP(props) {
  function drift_from_y(start, amount) {
    return start + amount * ((props.scrollPosition - start) / 300).toFixed(2);
  }

  return (
    <div className="relative flex flex-col align-center w-full overflow-hidden text-snow">
      <div className="relative z-30 flex flex-col items-center mt-[150px] mb-[75px]">
        <h1 className="font-bold">OUR LEADERSHIP</h1>
        <h2 className="text-center">
          Meet our President, Faculty Advisors, and Project Managers
        </h2>
      </div>
      <div className="relative flex flex-wrap justify-center z-20 mb-[100px] max-w-[2000px] mx-auto">
        <CARD
          photo={ETHAN}
          name={"Ethan Havemann"}
          title={"Founder, President, Short Horizons PM"}
          text={
            "Founder of NUFT, Ethan has been the catalyst to the club's growth and promising future. He is the current President and Technical Lead. Ethan has previously interned at Amazon, AWS, and Optiver, and will be returning to Optiver full-time in 2025."
          }
          linkedin={"https://www.linkedin.com/in/ethan-havemann/"}
          github={"https://github.com/echavemann"}
        />
        <CARD
          photo={JERRY}
          name={"Jerry Cao"}
          title={"Deep Learning PM"}
          text={
            "Jerry currently serves as DL PM, leading NUFT's long term predictions. He also generally serves as a mentor for propective traders, and has interned with IMC Trading in 2024. He will be joining IMC full-time as a quantitative trader in 2025. "
          }
          linkedin={"https://www.linkedin.com/in/jiarui-jerry-cao/"}
          github={"https://github.com/jiarui-cao"}
        />
        <CARD
          photo={RYAN}
          name={"Ryan Wong"}
          title={"Core PM"}
          text={
            "Ryan takes up the role of Core PM after a successful tenure as head of low level software. Ryan has interned at Roblox and Citadel, and will be returning to citadel for full-time in 2025. He is well known for his dominance in various course contests."
          }
          linkedin={"https://www.linkedin.com/in/rewong03/"}
          github={"https://github.com/rewong03"}
        />
        <CARD
          photo={STEVE}
          name={"Steve Ewald"}
          title={"Core PM"}
          text={
            "Steve takes the lead of the second Core team, leveraging his talent both in full-stack development and performance-critical software. He has interned at Amazon, Netflix, and IMC Trading. He will be joining Citadel Securities for full-time in 2025."
          }
          linkedin={"https://www.linkedin.com/in/steven-ewald/"}
          github={"https://github.com/stevenewald"}
        />
        <CARD
          photo={JAY}
          name={"Jay Park"}
          title={"Webmaster"}
          text={
            "Jay takes the role of NUFT's webmaster, utilizing his years spent in web development to work extensively on NUFTSite and tackle any requests by the club. He is interning for Motorola Solutions."
          }
          linkedin={"https://www.linkedin.com/in/jayhoonpark/"}
          github={"https://github.com/kyeoul"}
        />
        <CARD
          photo={JIALE}
          name={"Jiale Chen"}
          title={"Head of Reinforcement Learning"}
          text={
            "Jiale is NUFT's most seasoned machine learning developer, and leads the RL team after a successful year heading backtesting technology. Following his graduation in late 2024, Jiale will be pursuing a PhD in CS, advised by Prof. Zhaoran Wang."
          }
          linkedin={"https://www.linkedin.com/in/jc0402/"}
          github={"https://github.com/jiale0402"}
        />
        <CARD
          photo={AIDAN}
          name={"Aidan Villasenor"}
          title={"Head of Competitive Programming"}
          text={
            "Aidan holds one of the longest tenures in NUFT, and has become known as the de facto resource on competitive programming and algorithmic problems. He is working at Optiver as a SWE Intern for Summer 2024."
          }
          linkedin={"https://www.linkedin.com/in/aidanvillasenor/"}
          github={"https://github.com/aidanvillasenor"}
        />
        <CARD
          id="Ilya Mikhelson"
          photo={ILYA}
          name={"Ilya Mikhelson"}
          title={"Faculty Advisor"}
          text={
            "Our beloved faculty advisor, Ilya is a Professor of Electrical Engineering and part time CTO of Pareto Frontier Capital. He often lends his experience with all manner of subjects and ideas to guide NUFT and it's members."
          }
          linkedin={"https://www.linkedin.com/in/ilya-mikhelson-3480a3b/"}
          github={"https://github.com/IMikhelson"}
        />
        <CARD
          id="Zhaoran"
          photo={ZHAORAN}
          name={"Zhaoran Wang"}
          title={"Machine Learning Advisor"}
          text={
            "Zhaoran serves as NUFT's second faculty advisor, as well as being a Professor of Industrial Engineering. Zhaoran primarily aids the forecasting teams, especially RL - of which he is a foremost expert in."
          }
          linkedin={
            "https://scholar.google.com/citations?user=HSx0BgQAAAAJ&hl=en"
          }
          github={"https://github.com/zhaoranwang"}
        />

        <div className="fixed overflow-hidden w-full h-full -bottom-[20rem]">
          <img
            className="absolute z-10 w-full lg:mt-0 mt-[100px]"
            style={{ opacity: 100, top: drift_from_y(20, 3) }}
            src={require("../../assets/[H]blob_d.png")}
            alt="blob_d"
          />
          <img
            className="absolute z-20 w-full"
            style={{ opacity: 100, top: drift_from_y(350, -5) }}
            src={require("../../assets/[H]blob_f.png")}
            alt="blob_f"
          />
        </div>
      </div>
      <img
        className="fixed z-0 top-0 w-full object-cover h-[700px]"
        src={require("../../assets/[H]bg.png")}
        alt="grid"
      />
      <img
        className="fixed fadein top-0 left-0 rotate-180 min-w-full h-auto"
        style={{ height: "2000px" }}
        src={require("../../assets/[p]slide_background.png")}
        alt="gradient"
      />
    </div>
  );
}
