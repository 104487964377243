import React from "react";
import { collection, getDocs, query, where } from "firebase/firestore";
import { db } from "../../firebase";
import { useState, useEffect } from "react";
import Fuse from "fuse.js";
import PERSONCARD from "../../components/member_tracking/PERSONCARD";
import MEMBERBUTTON from "./components/MEMBERBUTTON";
import SIDEBAR from "../../components/member_tracking/directory_components/SIDEBAR";

function MEMBERS() {
  const [members, setMembers] = useState([]);
  const [filteredMembers, setFilteredMembers] = useState([]);
  const [input, setInput] = useState("");
  const [selected, setSelected] = useState({});
  const [currFilter, setCurrFilter] = useState("All");
  const [showSidePanel, setShowSidePanel] = useState(true);

  const fetchMembers = async (filter) => {
    setCurrFilter(filter);
    let q;
    if (filter === "") {
      q = query(collection(db, "users"), where("type", "!=", "Applicant"));
    } else {
      q = query(collection(db, "users"), where("type", "==", filter));
    }

    await getDocs(q).then((querySnapshot) => {
      const newData = querySnapshot.docs.map((doc) => ({
        ...doc.data(),
        name: doc.data().first_name + " " + doc.data().last_name,
        id: doc.id,
      }));
      setMembers(newData);
      setFilteredMembers(newData);
    });
  };

  const options = {
    keys: ["name"],
    includeAllMatches: true,
    includeScore: true,
    findAllMatches: true,
    threshold: 0.25,
  };

  const fuse = new Fuse(filteredMembers, options);

  useEffect(() => {
    fetchMembers("");
  }, []);

  useEffect(() => {
    currFilter
    ? setFilteredMembers(members.filter((m) => m.type === currFilter))
    : setFilteredMembers(members);
  }, [currFilter, members])

  let filterbuttons = ["", "Member", "Admin", "Sponsor"];

  return (
    <div className="h-screen bg-royalblue flex flex-row">
      <SIDEBAR filters={filterbuttons} currFilter={currFilter} setCurrFilter={setCurrFilter} showSidePanel={showSidePanel} setShowSidePanel={setShowSidePanel}/>
      <div style={{ 'overflow': 'hidden', 'transition': '0.5s', 'width': showSidePanel ? '30rem' : '0rem' }}>
        <div className="flex flex-col basis-1/3 h-full bg-[#141852] p-3 pt-24">
          <div className="flex text-white items-center">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              stroke-width="1.5"
              stroke="currentColor"
              class="w-16 h-16"
            >
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                d="M17.982 18.725A7.488 7.488 0 0012 15.75a7.488 7.488 0 00-5.982 2.975m11.963 0a9 9 0 10-11.963 0m11.963 0A8.966 8.966 0 0112 21a8.966 8.966 0 01-5.982-2.275M15 9.75a3 3 0 11-6 0 3 3 0 016 0z"
              />
            </svg>
            <div className="ml-2">
              <h2>Member Directory</h2>
              <div className="text-gray-500">{filteredMembers.length} People</div>
            </div>
          </div>

          <input
            className="w-full h-10 mt-4 rounded-sm bg-white bg-opacity-20 outline-none text-white p-2"
            value={input}
            onChange={(e) => setInput(e.target.value)}
            placeholder="Search"
          />

          <div className="flex-1 mt-2 w-full overflow-y-auto overflow-x-hidden flex flex-col items-start">
            {input
              ? fuse.search(input).map((member, i) => {
                  return <MEMBERBUTTON member={member.item} index={i} setSelected={setSelected}/>;
                })
              : filteredMembers.map((member, i) => {
                  return <MEMBERBUTTON member={member} index={i} setSelected={setSelected}/>;
                })}
          </div>
        </div>
      </div>
      <div className="flex flex-1 h-full bg-[#0a0d2b] p-6 pt-24">
        {Object.keys(selected).length !== 0 ? (
          <PERSONCARD member={selected} />
        ) : (
          <h2 className="w-full h-full flex justify-center items-center text-white">
            Select a member to get started...
          </h2>
        )}
      </div>
    </div>
  );
}

export default MEMBERS;
