import React from 'react'
import { Field } from "formik";

function INFO_L({category, info}) {
  return (
    <>
      <div className="member-info-big  profile-margins">
        <div className="member-info-label">{category}</div>
        <div className="member-info-line" />
      </div>

      <div className="member-info-margin">{info}</div>
    </>
  )
}

function FIELD_L({category, info, fieldname, validation, error, touched, ...props}) {
  return (
    <>
      <div className="member-info-big profile-margins">
        <div className="member-info-label">{category}</div>
        <div className="member-info-line" />
      </div>

      <div className="member-info-margin">
        <div>
          <Field
            id={fieldname}
            name={fieldname}
            className="bg-transparent hover:text-blue-400 text-white border-2 rounded-md p-2 outline-none w-full h-24"
            as="textarea"
          />
          {error && touched ? (
            <div className="profile-error">{error}</div>
          ) : null}
        </div>
      </div>
    </>
  )
}

export {INFO_L, FIELD_L}