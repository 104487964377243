import React from 'react'
import { Field, FieldArray, ErrorMessage } from "formik";

function INFOLIST_S({category, info, inline = false}) {

  let arrangement = ""
  if(inline){
    arrangement = "flex flex-row profile-margins flex-wrap"
  }
  else{
    arrangement = "flex flex-col items-end profile-margins"
  }

  return (
    <div className="w-full flex flex-col profile-margins">
      <div className="member-info-label">{category}</div>
      <div className={`${arrangement} text-sm`}>
        {info
          ? info.map((element, i) => {
              return (
                <>
                  <div className="text-md text-right w-fit text-nowrap">{element}</div>
                  {inline && i < info.length - 1 
                    ? <span className='mr-2'>,</span> 
                    : ""}
                </>
              );
            })
          : ""}
      </div>
    </div>
  )
}

function FIELDLIST_S({category, value, fieldname, validation, inline = false }) {

  let arrangement = ""
  if(inline){
    arrangement = "flex flex-row items-center flex-wrap"
  }
  else{
    arrangement = "flex flex-col items-end"
  }

  return (
    <div className="w-full flex flex-col profile-margins">
      <div className="member-info-label">{category}</div>
      <div className="flex flex-col items-end text-sm">
        <FieldArray name={fieldname}>
          {(arrayHelpers) => (
            <div className={arrangement}>
              {value.length > 0 &&
                value.map((element, index) => (
                  <div className="row" key={index}>
                    <div className="col flex flex-row items-center">
                      <button
                        type="button"
                        className="secondary m-auto hover:text-red-400 duration-200"
                        onClick={() =>
                          arrayHelpers.remove(index)
                        }
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          viewBox="0 0 24 24"
                          stroke-width="1.5"
                          stroke="currentColor"
                          class="w-6 h-6"
                        >
                          <path
                            stroke-linecap="round"
                            stroke-linejoin="round"
                            d="M6 18L18 6M6 6l12 12"
                          />
                        </svg>
                      </button>
                      <Field
                        name={`${fieldname}.${index}`}
                        type="text"
                        className="profile-field hover:text-blue-400"
                        validate={validation}
                      />
                    </div>
                    <ErrorMessage
                      name={`${fieldname}.${index}`}
                      component="div"
                      className="field-error"
                    />
                  </div>
                ))}
              <button
                type="button"
                className="secondary mt-2 hover:text-green-400 duration-200"
                onClick={() => {
                  arrayHelpers.push("");
                }}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke-width="1.5"
                  stroke="currentColor"
                  class="w-6 h-6"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    d="M12 4.5v15m7.5-7.5h-15"
                  />
                </svg>
              </button>
            </div>
          )}
        </FieldArray>
      </div>
    </div>
  )
}

export {INFOLIST_S, FIELDLIST_S}