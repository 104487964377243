import React from 'react'
import BUTTON from "../../../components/global/BUTTON";
import Gearicon from "../../../assets/settingsicon.png";

function SIDEBAR({filters, currFilter, setCurrFilter, showSidePanel, setShowSidePanel}) {
  return (
    <div className="flex flex-col h-full p-3 pt-24" style={{ 'width': '15rem' }}>
      {filters.map((filter) => {
        return (
          <button
            className={`flex flex-row items-center text-white hover:bg-white hover:bg-opacity-20 duration-200 p-2 rounded-md ${currFilter === filter
              ? "bg-white bg-opacity-20"
              : ""
              }`}
            onClick={() => {
              if(filter === "Shadow Realm"){
                filter = "Limbo"
              }
              setCurrFilter(filter);
              
            }}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              stroke-width="1.5"
              stroke="currentColor"
              class="w-8 h-8"
            >
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                d="M17.982 18.725A7.488 7.488 0 0012 15.75a7.488 7.488 0 00-5.982 2.975m11.963 0a9 9 0 10-11.963 0m11.963 0A8.966 8.966 0 0112 21a8.966 8.966 0 01-5.982-2.275M15 9.75a3 3 0 11-6 0 3 3 0 016 0z"
              />
            </svg>

            <div className="text-xl ml-2">{filter ? filter : "All"}</div>
          </button>
        );
      })}

      <div className="absolute bottom-4 left-6 z-50 my-4 py-2 text-white">
        <div style={{ 'margin-bottom': '0.5rem' }} onClick={() => setShowSidePanel(!showSidePanel)}>
          <BUTTON
            text={showSidePanel ? "Minimize" : "Maximize"}
            hovwidth={175}
            icon={Gearicon}
          />
        </div>
        <BUTTON
          text={"Admin Page"}
          icon={Gearicon}
          hovwidth={175}
          link={"/admin"}
        />
      </div>
    </div>
  )
}

export default SIDEBAR