import React, { useRef } from "react";
import Linkicon from "../../assets/linkicon.png";
import BUTTON from "../../components/global/BUTTON";
import { UserAuth } from "../../context/Authcontext";

// import emailjs from "@emailjs/browser";
import { useState } from "react";

export default function SIGNUP() {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [first_name, setFirstName] = useState("");
  const [last_name, setLastName] = useState("");
  const [confEmail, setConfEmail] = useState("");
  const [confPassword, setConfPassword] = useState("");
  const [error, setError] = useState("");
  const form = useRef();
  const { createUser } = UserAuth();

  const onSubmit = async (e) => {
    e.preventDefault();
    setError("");
    if (email.toLowerCase() !== confEmail.toLowerCase()) {
      setError("Emails do not match");
      alert("Emails do not match");
      return;
    }
    if (password !== confPassword) {
      setError("Passwords do not match");
      alert("Passwords do not match");
      return;
    }

    try {
      await createUser(email, password, first_name, last_name);
    } catch (e) {
      setError(e.message);
      console.log(e.message);
    }
  };

  return (
    <form className="ml-[100px] mt-[80px]" ref={form} onSubmit={onSubmit}>
      <div className="flex flex-1">
        <div className="mr-6">
          <label>
            <h4 className="mt-[20px] w-max">First Name</h4>
          </label>
          <input
            type="text"
            required
            label="First Name"
            className="block bg-transparent text-[14px] font-snow
                   border-b-2 p-[10px]"
            style={{ animationName: "expand", animationDuration: "500ms" }}
            onChange={(event) => setFirstName(event.target.value)}
            value={first_name}
            placeholder="First Name"
          />
        </div>

        <div>
          <label>
            <h4 className="mt-[20px] w-max">Last Name</h4>
          </label>
          <input
            type="text"
            required
            label="Last Name"
            className="block bg-transparent text-[14px] font-snow
                   border-b-2 p-[10px]"
            style={{ animationName: "expand", animationDuration: "500ms" }}
            onChange={(event) => setLastName(event.target.value)}
            value={last_name}
            placeholder="Last Name"
          />
        </div>
      </div>

      <label>
        <h4 className="mt-[20px]">Email</h4>
      </label>
      <input
        type="email"
        required
        label="Email address"
        className="block w-[100%] bg-transparent text-[14px] font-snow
                   border-b-2 p-[10px]"
        style={{ animationName: "expand", animationDuration: "500ms" }}
        onChange={(event) => setEmail(event.target.value)}
        value={email}
        placeholder="Email address"
      />

      <label>
        <h4 className="mt-[20px]">Confirm Email</h4>
      </label>
      <input
        type="email"
        required
        label="Confirm Email address"
        className="block w-[100%] bg-transparent text-[14px] font-snow
                   border-b-2 p-[10px]"
        style={{ animationName: "expand", animationDuration: "500ms" }}
        onChange={(event) => setConfEmail(event.target.value)}
        value={confEmail}
        placeholder="Confirm Email address"
      />

      <label>
        <h4 className="mt-[20px]">Password</h4>
      </label>
      <input
        type="password"
        required
        label="Create password"
        className="block w-[100%] bg-transparent text-[14px] font-snow
                   border-b-2 p-[10px]"
        style={{ animationName: "expand", animationDuration: "500ms" }}
        onChange={(event) => setPassword(event.target.value)}
        value={password}
        placeholder="Password"
      />

      <label>
        <h4 className="mt-[20px]">Confirm Password</h4>
      </label>
      <input
        type="password"
        required
        label="Confirm password"
        className="block w-[100%] bg-transparent text-[14px] font-snow
                   border-b-2 p-[10px]"
        style={{ animationName: "expand", animationDuration: "500ms" }}
        onChange={(event) => setConfPassword(event.target.value)}
        value={confPassword}
        placeholder="Confirm Password"
      />

      <div className="mt-[20px]">
        <BUTTON
          text={"Already have an account? Sign in"}
          icon={Linkicon}
          hovwidth={380}
          link={"/login"}
        />
      </div>

      <div className="mt-[20px]">
        <button type="submit">
          <BUTTON text={"Sign up"} icon={Linkicon} hovwidth={128} />
        </button>
      </div>
    </form>
  );
}
