import { BrowserRouter, Route, Routes } from "react-router-dom";
import React, { useState, useEffect } from "react";
import { AuthContextProvider } from "./context/Authcontext";

import CONTACT from "./pages/CONTACT/CONTACT";
import FOOTER from "./components/global/FOOTER";
import HEADER from "./components/global/HEADER";
import HOME from "./pages/HOME/HOME";
import LEADERSHIP from "./pages/LEADERSHIP/LEADERSHIP";
import TECHNOLOGIES from "./pages/TECHNOLOGIES/TECHNOLOGIES";
import MENU from "./components/global/MENU";
import PROJECTS from "./pages/PROJECTS/PROJECTS";
import APPLICATION from "./pages/APPLY/APPLICATION";
import LOGIN from "./pages/SIGNIN/LOGIN";
import SIGN_UP from "./pages/SIGNIN/SIGN_UP";
import PROFILE from "./pages/PROFILE/PROFILE";
import PROTECTED_ROUTE from "./auth/PROTECTEDROUTE";
import PROTECTED_ROUTE_ADMIN from "./auth/PROTECTEDROUTEADMIN";
import PROTECTED_ROUTE_MEMBER from "./auth/PROTECTEDROUTEMEMBER";
import ADMIN from "./pages/ADMIN/ADMIN";
import ADMINEDIT from "./pages/ADMIN/ADMINEDIT";
import RESOURCES from "./pages/RESOURCES/RESOURCES";
import MEMBERS from "./pages/MEMBERS/MEMBERS";
import FORGOTPASS from "./pages/SIGNIN/FORGOTPASS";
import "./App.css";
import APPLICANT from "./pages/APPLICANTS/APPLICANT";
import OUTCOMES from "./pages/OUTCOMES/OUTCOMES";

/**
 * The parent of the entire webapp.
 * @returns 
 */

function App() {
  const [scrollPosition, setScrollPosition] = useState(0);

  const onScroll = () => {
    const position = window.pageYOffset;
    setScrollPosition(position);
  };

  useEffect(() => {
    window.addEventListener("scroll", onScroll, { passive: true });
    return () => {
      window.removeEventListener("scroll", onScroll);
    };
  });

  function reactive_header() {
    if (
      scrollPosition > window.innerHeight - 100 ||
      window.location.pathname !== "/"
    ) {
      return <HEADER scrollPosition={scrollPosition} />;
    }
  }
  return (
    <div className="flex min-h-screen flex-col">
      <div className="flex min-h-screen flex-col">
        <BrowserRouter>
          <AuthContextProvider>
            <MENU />
            {reactive_header()}
            <Routes>
              <Route
                path="/"
                element={<HOME scrollPosition={scrollPosition} />}
              />
              <Route
                path="/leadership"
                element={<LEADERSHIP scrollPosition={scrollPosition} />}
              />
              <Route path="/projects" element={<PROJECTS />} />
              <Route path="/outcomes" element={<OUTCOMES />} />
              <Route path="/technologies" element={<TECHNOLOGIES />} />
              <Route path="/contact" element={<CONTACT />} />
              <Route path="/application" element={<APPLICATION />} />
              <Route path="/login" element={<LOGIN />} />
              <Route path="/signup" element={
                <PROTECTED_ROUTE_ADMIN>
                  <SIGN_UP />
                </PROTECTED_ROUTE_ADMIN>
              } />
              <Route path="/passwordreset" element={<FORGOTPASS />} />

              <Route
                path="/members"
                element={
                  <PROTECTED_ROUTE_MEMBER>
                    <MEMBERS />
                  </PROTECTED_ROUTE_MEMBER>
                }
              />
              <Route
                path="/resources"
                element={
                  <PROTECTED_ROUTE_MEMBER>
                    <RESOURCES />
                  </PROTECTED_ROUTE_MEMBER>
                }
              />
              <Route
                path="/profile"
                element={
                  <PROTECTED_ROUTE>
                    <PROFILE />
                  </PROTECTED_ROUTE>
                }
              />
              <Route
                path="/admin"
                element={
                  <PROTECTED_ROUTE_ADMIN>
                    <ADMIN />
                  </PROTECTED_ROUTE_ADMIN>
                }
              />
              <Route
                path="/adminedit"
                element={
                  <PROTECTED_ROUTE_ADMIN>
                    <ADMINEDIT />
                  </PROTECTED_ROUTE_ADMIN>
                }
              />
              <Route
                path="/applicants"
                element={
                  <PROTECTED_ROUTE_ADMIN>
                    <APPLICANT />
                  </PROTECTED_ROUTE_ADMIN>
                }
              />
            </Routes>
          </AuthContextProvider>
        </BrowserRouter>
      </div>
      {<FOOTER />}
    </div>
  );
}

export default App;
