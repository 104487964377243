const faqs = [
  {
    id: 1,
    question: "What's the application process for NUFT?",
    answer:
      "Our application process for Spring will be revealed soon! As usual, we hope to have a concise process and be expedient in distributing results.",
  },
  {
    id: 2,
    question: "What's the time commitment as a member?",
    answer:
      "We expect members to spend 5-10 hours per week on NUFT across workshops, projects, and meetings. We expect our members place a high level of priority on NUFT events, and by extension, their careers.",
  },
  {
    id: 3,
    question: "Why should I join?",
    answer:
      "NUFT is a community of some of the brightest and most dedicated Math and CS students at Northwestern, all working together and supporting each other in achieving their goals. If this resonates with you, then Fintech would be a good fit. ",
  },
  {
    id: 4,
    question: "What kind of projects do you work on?",
    answer:
      "We work on a variety of projects, from data science, machine learning (deep, reinforcement, etc), distributed systems, and more.",
  },
  {
    id: 5,
    question: "What is the trading contest?",
    answer:
      "The trading contest is an algorithmic trading competition where participants write algorithms to compete against each other on a simulated market.",
  },
  {
    id: 6,
    question: "What is the goal of the club?",
    answer:
      "NUFT aims to build a diverse and supportive community of people interested in and dedicated to pursuing elite careers in various areas of financial technology.",
  },
];

export default function FAQ() {
  return (
    <div className="pt-48">
      <div className="mx-auto max-w-7xl px-6 py-16 sm:py-24 lg:px-8">
        <div className="mx-auto max-w-2xl text-center">
          <h2 className="text-2xl font-bold leading-10 tracking-tight text-gray-900">
            Frequently asked questions
          </h2>
          <p className="mt-6 text-base leading-7 text-gray-600">
            Have a different question and can’t find the answer you’re looking
            for? Reach out to our support team by{" "}
            <a
              href="mailto:nuft@u.northwestern.edu"
              className="font-semibold text-indigo-600 hover:text-indigo-500"
            >
              sending us an email.
            </a>{" "}
          </p>
        </div>
        <div className="mt-20">
          <dl className="space-y-16 sm:grid sm:grid-cols-2 sm:gap-x-6 sm:gap-y-16 sm:space-y-0 lg:grid-cols-3 lg:gap-x-10">
            {faqs.map((faq) => (
              <div key={faq.id}>
                <dt className="text-base font-semibold leading-7 text-gray-900">
                  {faq.question}
                </dt>
                <dd className="mt-2 text-base leading-7 text-gray-600">
                  {faq.answer}
                </dd>
              </div>
            ))}
          </dl>
        </div>
      </div>
    </div>
  );
}
