import React, { useRef } from "react";
import Linkicon from "../../../assets/linkicon.png";

import emailjs from "@emailjs/browser";
import { useState } from "react";
import EDITBUTTON from "../../../components/global/EDITBUTTON";

export default function FORM() {
  const [fullName, setFullName] = useState("");
  const [email, setEmail] = useState("");
  const [subject, setSubject] = useState("");
  const [message, setMessage] = useState("");

  const form = useRef();

  const sendEmail = (e) => {
    e.preventDefault();
    emailjs
      .sendForm(
        "service_0qabqac",
        "template_brgt3xu",
        form.current,
        "H1b9p-pcdrDw174_t",
      )
      .then(
        (result) => {
          console.log(result);
          alert("email sent");
          setFullName("");
          setEmail("");
          setSubject("");
          setMessage("");
        },
        (error) => {
          console.log(error.text);
        },
      );
  };

  return (
    <form className="ml-[100px] mt-[80px]" ref={form} onSubmit={sendEmail}>
      <label>
        <h4>Full Name</h4>
      </label>
      <input
        type="text"
        name="from_name"
        className="block w-[100%] bg-transparent text-[14px] font-snow
                   border-b-2 p-[10px]"
        style={{ animationName: "expand", animationDuration: "500ms" }}
        onChange={(e) => setFullName(e.target.value)}
        value={fullName}
      />

      <label>
        <h4 className="mt-[20px]">Email</h4>
      </label>
      <input
        type="email"
        name="reply_to"
        className="block w-[100%] bg-transparent text-[14px] font-snow
                   border-b-2 p-[10px]"
        style={{ animationName: "expand", animationDuration: "500ms" }}
        onChange={(event) => setEmail(event.target.value)}
        value={email}
      />
      <label>
        <h4 className="mt-[20px]">Subject</h4>
      </label>
      <input
        type="text"
        name="subject"
        className="block w-[100%] bg-transparent text-[14px] font-snow
                   border-b-2 p-[10px]"
        style={{ animationName: "expand", animationDuration: "500ms" }}
        onChange={(event) => setSubject(event.target.value)}
        value={subject}
      />
      <label>
        <h4 className="mt-[20px]">Message</h4>
      </label>
      <textarea
        name="message"
        style={{
          display: "block",
          width: 465,
          height: 200,
          border: 0,
          fontSize: 20,
          boxShadow: "inset 0px 0px 20px 3px #FFFFFF",
          background: "rgba(217, 217, 217, 0.1)",
          border: "none",
          borderRadius: 4,
          color: "#FFFFFF",
          padding: 30,
          backgroundColor: "transparent",
          marginTop: "20px",
        }}
        onChange={(event) => setMessage(event.target.value)}
        value={message}
      />
      <div className="mt-[20px]">
        <EDITBUTTON
          text={"Submit"}
          icon={Linkicon}
          hovwidth={120}
          type={"submit"}
        />
      </div>
    </form>
  );
}
