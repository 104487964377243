import React from 'react'
import BUTTON from '../../global/BUTTON';

function FILEUPLOAD({icon, link, fieldname, accept, setState, setField}) {
  return (
    <div className='flex flex-row items-center profile-margins'>
      <BUTTON icon={icon} link={link} />

      <input
        className='w-48'
        id={fieldname}
        name={fieldname}
        type="file"
        accept={accept}
        onChange={(e) => {
          setField(
            fieldname,
            e.currentTarget.files[0].name,
          );
          setState(e.currentTarget.files[0]);
        }}
      />
    </div>
  )
}

export default FILEUPLOAD