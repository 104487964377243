import CARDCANVAS from "./profile_components/CARDCANVAS";
import CARDLEFT from "./profile_components/CARDLEFT";
import CARDRIGHT from "./profile_components/CARDRIGHT";
import { DROPDOWNINFO } from "./profile_components/DROPDOWN";
import { HREFINFO } from "./profile_components/HREF";
import { INFOLIST_L } from "./profile_components/INFOLIST_L";
import { INFOLIST_S } from "./profile_components/INFOLIST_S";
import { INFO_L } from "./profile_components/INFO_L";
import { INFO_S } from "./profile_components/INFO_S";
import Githubicon from "../../assets/githubicon.png";
import LinkediniconWhite from "../../assets/linkedin_icon.png";
import Resumeicon from "../../assets/cv.png";

function PERSONCARD({member}) {
  // use type field of member
  return (
    <CARDCANVAS>
      <CARDLEFT member={member}>

        <h2 className="">
          {member.prefname ? member.prefname : member.first_name}{" "}
          {member.last_name}
        </h2>

        <div className="hover:text-lightcoral profile-margins  text-blue-300 text-balance break-words w-full overflow-hidden">
          <a href={`mailto: ${member.email}`}>{member.email}</a>
        </div>

        <div className="profile-margins">
          {/* <div className="member-info-label">Role</div> */}
          <div className="text-sm font-bold text-center border-2 rounded-md p-2 bold border-blue-500">
            {member.role}
          </div>
        </div>

        <div className="profile-margins">
          <HREFINFO 
            text={"Github"}
            icon={Githubicon}
            hovwidth={115}
            link={member.github}/>
          <HREFINFO 
            text={"LinkedIn"}
            icon={LinkediniconWhite}
            hovwidth={135}
            link={member.linkedin}/>
          <HREFINFO 
            text={"Resume"}
            icon={Resumeicon}
            hovwidth={130}
            link={member.resume}/>
        </div>
        <INFO_S category={"Pronouns"} info={member.pronouns}/>
        <INFO_S category={"Class Year"} info={member.standing}/>
        <INFO_S category={"GPA"} info={member.gpa}/>
        <INFOLIST_S category={"Degree(s)"} info={member.degrees}/>
        <INFOLIST_S category={"Minor(s)"} info={member.minors}/>
        <INFOLIST_S category={"Certificates(s)"} info={member.certificates}/>
      </CARDLEFT>

      <CARDRIGHT member={member}>
        <INFO_L category={"About"} info={member.about}/>
        <INFO_L category={"Relevant Experience"} info={member.experience}/>
        <INFOLIST_L category={"Relevant Internships"} info={member.internships} objFields={["company", "position", "year"]} color={"rgb(192, 132, 252)"}/>
        <DROPDOWNINFO category={"Desired Career"} info={member.career}/>
        <INFOLIST_S category={"Interests"} info={member.interests} inline={true}/>
        <INFO_L category={"Additional Info"} info={member.addinfo}/>
      </CARDRIGHT>
    </CARDCANVAS>
  );
}

export default PERSONCARD