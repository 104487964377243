import React from 'react'
import LOGO from "./LOGO"

function DESKTOPDISPLAY({feature, company, assetPrefix}) {
  return (
    <>
      <div className='flex flex-row justify-around w-full'>
        {
          feature.map((obj) => <LOGO logo={assetPrefix + obj.logo} size={2} delay={400} numMembers={obj.numMembers}/>)
        }
      </div>

      <div className='w-[90%] flex flex-wrap flex-row justify-center'>
        {
          company.map((obj, i) => {

            const rownum = Math.floor(i / 4)
            const delay = 700 + rownum * 200

            return (
              <LOGO logo={assetPrefix + obj.logo} size={1} delay={delay} numMembers={obj.numMembers}/>
            )
          })
        }
      </div>
    </>
  )
}


export default DESKTOPDISPLAY