import { useNavigate } from "react-router-dom";
import { UserAuth } from "../context/Authcontext";

const ProtectedRouteMember = ({ children }) => {
  const { user } = UserAuth();
  // const [users, setUsers] = useState([]);
  // const [mounted, setMounted] = useState(true);
  //const usersRef = collection(db, "users");
  const navigate = useNavigate();

  // useEffect(() => {
  //   const getUsers = async () => {
  //     const response = await getDocs(usersRef);
  //     setUsers(response.docs.map((doc) => ({ ...doc.data(), id: doc.id })));
  //   };
  //   if (mounted) {
  //     getUsers();
  //   }
  //   return () => {
  //     setMounted(false);
  //   };
  // }, [usersRef, mounted]);

  if (!user) {
    console.log("user not logged in");
    navigate("/login");
  }

  if (
    user.type === "Admin" ||
    user.type === "Member" ||
    user.type === "Sponsor"
  ) {
    return children;
  }
  console.log("Ya ain't a member, kiddo");
  navigate("/");
  return null;
};

export default ProtectedRouteMember;
