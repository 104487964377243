import { useNavigate } from "react-router-dom";
import { UserAuth } from "../context/Authcontext";

const ProtectedRouteAdmin = ({ children }) => {
  const { user } = UserAuth();
  //  const [users, setUsers] = useState({});
  //  const [mounted, setMounted] = useState(true);
  const navigate = useNavigate();
  // const userRef = doc(db, "users", user.uid);

  // useEffect(() => {

  //   const getUsers = async () => {
  //     const response = await getDoc(userRef);
  //     setUsers(response.data());
  //   };
  //   if (mounted) {
  //     getUsers();
  //   }
  //   return () => {
  //     setMounted(false);
  //   };
  // }, [userRef, mounted]);

  if (!user) {
    console.log("user not logged in");
    navigate("/login");
  }

  if (user.type === "Admin") {
    return children;
  }
  navigate("/");
  return null;
};

export default ProtectedRouteAdmin;
