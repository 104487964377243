import {React} from 'react'
import JSONLU from "../../companycloud.json"
import { animated, useSpring } from "react-spring"
import { useMediaQuery } from 'react-responsive';
import MOBILEDISPLAY from './components/MOBILEDISPLAY';
import DESKTOPDISPLAY from './components/DESKTOPDISPLAY'

// TODO: Refractor lowkey
// This codebase is an escher painting

const assetPrefix = process.env.PUBLIC_URL + "/assets/outcomes/logos/"

// main outcomes
function OUTCOMES() {

  const numAlumni = 0

  const FEATURECLOUD = JSONLU.featureCloud

  const COMPANYCLOUD = JSONLU.companyCloud

  FEATURECLOUD.sort((a, b) => {
    return b.priority - a.priority
  })

  COMPANYCLOUD.sort((a, b) => {
    return b.priority - a.priority
  })

  const introStyle = useSpring({
    from: {opacity: 0},
    to: {opacity: 1},
    delay: 100
  })

  const isDesktop = useMediaQuery({ query: `(min-width: 768px)` });

  return (
    <div className='w-full min-h-screen bg-white flex justify-center'>
        <div className='flex flex-col items-center w-4/5 mt-36 pb-12'>
            <animated.div style={introStyle} className="flex flex-col items-center w-full">
              <h1 className="font-semibold">Where We Work</h1>
              <h4 className="text-center roboto md:w-1/2 mt-4 md:mt-10 max-w-[2000px] mx-auto">
                  Curious to see how NUFT has helped land its members {numAlumni > 0 ? "and alumni" : ""} top offers in tech and finance? Here’s where our members have worked:
              </h4>
            </animated.div>

            {isDesktop ? <DESKTOPDISPLAY feature={FEATURECLOUD} company={COMPANYCLOUD} assetPrefix={assetPrefix}/> : <MOBILEDISPLAY feature={FEATURECLOUD} company={COMPANYCLOUD} assetPrefix={assetPrefix}/>}
        </div>
    </div>
  )
}

export default OUTCOMES
