import React, { useState, useEffect } from "react";
import ADMINCARD from "../../components/member_tracking/ADMINCARD";
import { useNavigate } from "react-router-dom";
import { UserAuth } from "../../context/Authcontext";
import { db } from "../../firebase";
import { collection, getDocs } from "firebase/firestore";

export default function ADMIN() {
  const { user, logout } = UserAuth();
  const [users, setUsers] = useState([]);
  const [mounted, setMounted] = useState(true);
  const usersRef = collection(db, "users");
  const navigate = useNavigate();

  const handleLogout = async () => {
    try {
      await logout();
      navigate("/login");
      console.log("logged out");
    } catch (e) {
      console.log(e.message);
    }
  };

  useEffect(() => {
    const getUsers = async () => {
      const response = await getDocs(usersRef);
      setUsers(response.docs.map((doc) => ({ ...doc.data(), id: doc.id })));
    };
    if (mounted) {
      getUsers();
    }
    return () => {
      setMounted(false);
    };
  }, [usersRef, mounted]);

  return (
    <div
      className="relative flex flex-col align-center w-full overflow-hidden
    bg-gradient-to-br from-royalblue to-black text-snow"
    >
      <div className="relative flex flex-row justify-center z-10 p-20">
        <div className="flex flex-col w-11/12 align-center mt-[80px] text-snow">
          <h1 className="font-bold relative flex flex-row justify-center">
            Welcome, admin!&nbsp;
          </h1>
          <h4 className="relative flex flex-row justify-center mb-4">
            User Management Portal
          </h4>
          <div className="relative flex flex-col align-center w-full overflow-hidden text-snow ">
            <div className="relative flex flex-wrap justify-center z-20 mb-[100px] h-fit bg-red-800 rounded-lg w-full overflow-auto">
              <div className="mt-2 font-bold text-xl">Admins</div>
              {users.map((member) => {
                if (member.type === "Admin")
                  return (
                    <ADMINCARD
                      name={member.first_name + " " + member.last_name}
                      email={member.email}
                      role={member.type}
                      uid={member.uid}
                      user={user}
                    />
                  );
              })}
            </div>
            <div className="relative flex flex-wrap justify-center z-20 mb-[100px] h-fit bg-emerald-700 rounded-lg w-full overflow-auto">
              <div className="mt-2 font-bold text-xl">Members</div>
              {users.map((member) => {
                if (member.type === "Member")
                  return (
                    <ADMINCARD
                      name={member.first_name + " " + member.last_name}
                      email={member.email}
                      role={member.type}
                      uid={member.uid}
                      user={user}
                    />
                  );
              })}
            </div>
            <div className="relative flex flex-wrap justify-center z-20 mb-[100px] h-fit bg-cyan-700 rounded-lg w-full overflow-auto">
              <div className="mt-2 font-bold text-xl">Applicants</div>
              {users.map((member) => {
                if (member.type === "Applicant")
                  return (
                    <ADMINCARD
                      name={member.first_name + " " + member.last_name}
                      email={member.email}
                      role={member.type}
                      uid={member.uid}
                      user={user}
                    />
                  );
              })}
            </div>
          </div>

          <div className="relative flex flex-row justify-center font-bold">
            <button onClick={handleLogout}>Logout</button>
          </div>
        </div>
      </div>
      <img
        className="fixed z-0 top-0 w-full object-cover h-full"
        src={require("../../assets/[H]bg.png")}
        alt="grid"
      />
      <img
        className="fixed fadein top-0 left-0"
        style={{ height: "2000px" }}
        src={require("../../assets/[c]contact_background.png")}
        alt="gradient"
      />
    </div>
  );
}
