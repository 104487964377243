import { createContext, useContext, useState, useEffect } from "react";
import {
  createUserWithEmailAndPassword,
  signInWithEmailAndPassword,
  signOut,
  onAuthStateChanged,
} from "firebase/auth";
import { auth } from "../firebase";
import { useNavigate } from "react-router-dom";
import { db } from "../firebase";
import { setDoc, doc, getDoc } from "firebase/firestore";

const UserContext = createContext();

export const AuthContextProvider = ({ children }) => {
  const [user, setUser] = useState({});
  const navigate = useNavigate();

  const createUser = (email, password, first_name, last_name) => {
    createUserWithEmailAndPassword(auth, email, password)
      .then((userCredential) => {
        // Signed in
        const user = userCredential.user;
        // Adding user to the firestore database
        try {
          setDoc(doc(db, "users", user.uid), {
            email: email,
            first_name: first_name,
            last_name: last_name,
            type: "Applicant",
            uid: user.uid,
            linkedin: "https://www.linkedin.com",
            github: "https://www.github.com",
            resume: "",
            role: "Your NUFT role",
            standing: "Your class year",
            majors: ["Major1", "Major2"],
            gpa: "X.XX",
            hundredk: "0",
            about: "Tell us about yourself!",
            internships: [
              {
                company: "Company",
                position: "Position",
                year: "Year",
              },
              {
                company: "Company",
                position: "Position",
                year: "Year",
              },
            ],
            projects: [],
            interests: ["Interest1", "Interest2"],
            prefname: first_name,
            pronouns: "Preferred Pronouns",
            degrees: ["Bachelor of ...", "Degree2"],
            minors: ["Minor1", "Minor2"],
            certificates: ["Certificate1", "Certificate2"],
            team: "Desired NUFT Team",
            experience: "Previous related experience",
            commitments: [
              {
                club: "Commitment",
                hours: "",
                position: "Position",
              },
            ],
            career: "Desired Career Path",
            status: "Pending",
            addinfo: "",
          });
          navigate("/profile", { state: { uid: user.uid } });
        } catch (e) {
          console.log(e.message);
        }

        // ...
      })
      .catch((error) => {
        const errorCode = error.code;
        const errorMessage = error.message;
        console.log(errorCode, errorMessage);
        if (errorMessage === "Firebase: Error (auth/email-already-in-use).") {
          alert("Email already in use.");
        } else if (
          errorMessage ===
          "auth/weak-password Firebase: Password should be at least 6 characters (auth/weak-password)."
        ) {
          alert("Password must be at least 6 characters.");
        } else if (errorMessage === "Firebase: Error (auth/invalid-email).") {
          alert("Invalid email.");
        }

        // ..
      });
  };

  const signIn = (email, password) => {
    signInWithEmailAndPassword(auth, email, password)
      .then((userCredential) => {
        // Signed in
        const user = userCredential.user;
        navigate("/profile", {
          state: { uid: user.uid },
        });
        // ...
      })
      .catch((error) => {
        const errorCode = error.code;
        const errorMessage = error.message;
        console.log(errorCode, errorMessage);
        alert("Error logging in. Please check your credentials.");
      });
  };

  const logout = () => {
    return signOut(auth);
  };

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      // console.log("currentUser: ", currentUser);
      if (user) {
        let userRef = doc(db, "users", user.uid);
        const getProfile = async () => {
          console.log("Pulled profile data");
          const response = await getDoc(userRef);
          setUser(response.data());
        };
        getProfile();
      } else {
        setUser(null);
      }
    });
    return () => {
      unsubscribe();
    };
  }, []);

  return (
    <UserContext.Provider value={{ createUser, user, logout, signIn }}>
      {children}
    </UserContext.Provider>
  );
};

export const UserAuth = () => {
  return useContext(UserContext);
};
