import React from 'react'
import nufticon from "../../../assets/nufticon.png";

function CARDLEFT({children, member}) {
  return (
    <div className="flex-none w-[35%] box-border relative flex flex-col items-center">
      <img
        className="rounded-full w-52 h-52 -translate-y-1/2 absolute object-cover object-center border-4"
        id="user_pfp"
        alt="member-icon"
        src={member.pfp ? member.pfp : nufticon}
      />

      {/* To break away from profile picture... */}
      <div className="mt-28 w-full px-8 flex flex-col items-center pb-4">
        {children}
      </div>
    </div>
  )
}

export default CARDLEFT