import React, { useState } from "react";
import { useSpring, animated } from "react-spring";

export default function BUTTON({newtab = false, ...props}) {
  const [hovered, setHovered] = useState(false);

  const springstyle = useSpring({
    width: hovered ? props.hovwidth : 28,
    background: hovered ? "rgba(248, 240, 240, 0.5)" : "rgba(248, 240, 240, 0)",
    height: 28,
  });

  return (
    <div className={props.className}>
      <a
        className="inline-flex justify-start items-center"
        onMouseLeave={() => setHovered(false)}
        onMouseEnter={() => setHovered(true)}
        href={props.link}
        target={newtab ? "_blank" : '_self'}
        rel={newtab ? "noreferrer" : ""}
      >
        <img
          className="object-scale-down h-4 ml-1.5 flex-none"
          src={props.icon}
          alt="icon"
        />
        <div className="ml-4 text-xl">{props.text}</div>
        <div className="absolute">
          <animated.div
            className="bg-snow border border-snow border-2 rounded-full"
            style={springstyle}
          />
        </div>
      </a>
    </div>
  );
}
