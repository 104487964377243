import React from 'react'
import { Field } from "formik";

function INFO_S({category, info}) {
  return (
    <div className="member-info profile-margins">
      <div className="member-info-label">{category}</div>
      <div className="text-sm">{info}</div>
    </div>
  )
}

function FIELD_S({category, info, fieldname, validation, error, touched}) {
  return (
    <div className="member-info profile-margins">
      <div className="member-info-label">{category}</div>
      <div>
        <Field
          id={fieldname}
          name={fieldname}
          className="profile-field hover:text-blue-400"
        />
        {error && touched ? (
          <div className="profile-error">
            {error}
          </div>
        ) : null}
      </div>
    </div>
  )
}

export { INFO_S, FIELD_S }