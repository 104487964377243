import "../../App.css";
import { useRef, useState } from "react";
import BUTTON from "../../components/global/BUTTON";
import Linkicon from "../../assets/linkicon.png";
import { getAuth, sendPasswordResetEmail } from "firebase/auth";
import { useNavigate } from "react-router-dom";

export default function FORGOTPASS() {
  const [email, setEmail] = useState("");
  const [error, setError] = useState("");
  const form = useRef();
  const auth = getAuth();
  const navigate = useNavigate();

  const onReset = async (e) => {
    e.preventDefault();
    setError("");
    try {
      await sendPasswordResetEmail(auth, email)
        .then(() => {
          // Password reset email sent!
          // ..
          alert("Password reset email sent!");
          navigate("/login");
        })
        .catch((error) => {
          const errorCode = error.code;
          const errorMessage = error.message;
          console.log(errorCode, errorMessage);
          alert(
            "Error sending password reset email. Make sure you entered a valid email address.",
          );
          // ..
        });
    } catch (e) {
      setError(e.message);
      console.log(e.message);
    }
  };

  return (
    <div
      className="relative flex flex-col justify-center items-center w-full h-screen overflow-hidden
    bg-gradient-to-br from-royalred to-black text-snow"
    >
      <div className="relative flex flex-row justify-between z-10 p-20 max-w-[2000px] mx-auto">
        <div className="flex flex-col w-1/2 mt-[80px] text-snow">
          <h1 className="font-bold">Password Reset</h1>
          <h6>
            Enter your email address and we'll send you a link to reset your
            password.
          </h6>
        </div>
        <form
          className="ml-[100px] mt-[80px] max-w-[2000px] mx-auto"
          ref={form}
          onSubmit={onReset}
        >
          <label>
            <h4 className="mt-[20px]">Email</h4>
          </label>
          <input
            type="email"
            required
            label="Email address"
            className="block w-[100%] bg-transparent text-[14px] font-snow
                   border-b-2 p-[10px]"
            style={{ animationName: "expand", animationDuration: "500ms" }}
            onChange={(event) => setEmail(event.target.value)}
            value={email}
            placeholder="Email address"
          />

          <div className="mt-[20px]">
            <button type="submit">
              <BUTTON text={"Reset Password"} icon={Linkicon} hovwidth={225} />
            </button>
          </div>
        </form>
      </div>
      <img
        className="fixed z-0 top-0 w-full object-cover h-full"
        src={require("../../assets/[H]bg.png")}
        alt="grid"
      />
      <img
        className="fixed fadein top-0 left-0 w-full"
        style={{ height: "1000px" }}
        src={require("../../assets/[H]blob_g.png")}
        alt="gradient"
      />
    </div>
  );
}
