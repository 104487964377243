import React from "react";

export default function SHORTCARD(props) {
  return (
    <div
      className="z-20 bg-black rounded-xl overflow-hidden shadow-lg m-4 drop-shadow-2xl
                        lg:w-[12.5vw] md:w-[20vw] sm:w-[22.5vw] w-[37.5vw]"
    >
      <img
        className="fadein object-scale-down w-11/12 h-[125px] card-center rounded-lg"
        src={props.photo}
        alt={props.name}
      />
      <div className="px-6 py-4 flex flex-col items-center">
        <div className="font-bold text-xl">{props.name}</div>
        <div className="text-lg m-1 text-gray-70">
          <em>{props.title}</em>
        </div>
      </div>
    </div>
  );
}
