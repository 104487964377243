import React, { useState, useCallback, useRef } from "react";
import SLIDES from "../../components/home_page/SLIDES";
import interview from "../../assets/interview.png";
import opportunity from "../../assets/opportunity.png";
import nufticon from "../../assets/nufticon.png";
import { useSpring, animated } from "react-spring";
import { useSwipeable } from "react-swipeable";

export default function APPLICATION(props) {
  const [index, setIndex] = useState(0);

  const springstyle = useSpring({
    transform: `translateY(-${index * 100}%)`,
  });

  const colorspring = useSpring({
    backgroundColor: `rgba(0, 0, 0, ${(index - 1) * 0.33})`,
  });

  let content = [
    // {
    //   length: 180,
    //   text: "Apply Now",
    //   link: "/login",
    //   icon: interview,
    //   title: "Interviews",
    //   description:
    //     "The first round interview will be a technical interview, testing you on your data structure and algorithm skills as well as other technical abilities that may be necessary for NUFT. The second round interview will be performed by two senior NUFT members, and will assess applicants as a whole to ensure they are ready to join.",
    // },
    // {
    //   length: 180,
    //   text: "Apply Now",
    //   link: "/login",
    //   icon: opportunity,
    //   title: "Underclassmen Opportunities",
    //   description:
    //     "We will be hosting an application session in the 2nd week of Spring focusing on freshmen and sophomores. This recruitment cycle will focus primarily on pre-professional readiness and academic performance. ",
    // },
    // {
    //   length: 180,
    //   text: "Apply Now",
    //   link: "/login",
    //   icon: nufticon,
    //   title: "Interested?",
    //   description: "Click the button below to apply!",
    // },
  ];

  var lastAnimation = 0;
  var animationTime = 200; // in ms
  var quietPeriod = 500; // in ms, time after animation to ignore mousescroll

  const onWheel = useCallback(
    (e) => {
      e.preventDefault();
      e.stopPropagation();
      var timeNow = new Date().getTime();

      // change this to deltaX/deltaY depending on which
      // scrolling dir you want to capture
      const deltaOfInterest = e.deltaY;
  
      if (deltaOfInterest == 0) {
          // Uncomment if you want to use deltaX
          return;
      }
  
      // Cancel scroll if currently animating or within quiet period
      if(timeNow - lastAnimation < quietPeriod + animationTime) {
          return;
      }
  
      if (deltaOfInterest < 0) {
        setIndex(prev => {
          return Math.max(0, prev - 1)
        })
        lastAnimation = timeNow;
      } 
      else {
        setIndex(prev => {
          return Math.min(prev + 1, content.length)
        })
        lastAnimation = timeNow;
      }
    },
    [],
  );

  // Thank you my god stack overflow https://stackoverflow.com/questions/63663025/react-onwheel-handler-cant-preventdefault-because-its-a-passive-event-listenev
  const divRef = useRef(null);
  const divRefCallback = useCallback(
    (node) => {
      if (node == null) {
        if (divRef.current != null) {
          /* This happens when the <div> component tries to detach the old divRefCallback.
           * Since we are inside a `useCallback`, the `onWheel` callback being remove is still the old one here.
           */
          divRef.current.removeEventListener('wheel', onWheel, { passive: false });
        }
        return;
      }
      divRef.current = node;
      node.addEventListener('wheel', onWheel, { passive: false });
    },
    [onWheel],
  );

  const handler = useSwipeable({
    onSwipedUp: () => {
      setIndex(Math.min(index + 1, content.length))
      console.log("swiped up")
    },
    onSwipedDown: () => {
      setIndex(Math.max(0, index - 1))
      console.log("swiped down")
    },
    swipeDuration: 500,
    preventScrollOnSwipe: true,
    trackMouse: true
  })

  function drift_from_y(start, amount) {
    return start + amount * ((props.scrollPosition - start) / 300).toFixed(2);
  }

  return (
    <div className="flex-grow flex flex-col relative overflow-hidden"
      {...handler}
      ref={divRefCallback}>
      <animated.div
        className="absolute z-10 h-screen w-full bg-blend-screen"
        style={colorspring}
      >
        <img
          className="absolute top-0 left-0 w-full min-h-screen"
          src={require("../../assets/[p]slide_background.png")}
          alt="slidebg"
        />
      </animated.div>

      <animated.div className="absolute z-10 h-full" style={springstyle}>
        <section
          id="intro"
          className="flex flex-col justify-center items-center h-full w-screen bg-gradient-to-br from-royalblue via-black to-denimblue text-snow"
        >
          <img
            className="absolute lg:w-[70%] md:w-[80%] sm:w-[90%] w-[90%] opacity-50 md:opacity-75 lg:opacity-100 object-cover"
            style={{ left: "75%", top: drift_from_y(100, -10) }}
            src={require("../../assets/[H]blob_c_1.png")}
            alt="blob_c"
          />
          <img
            className="absolute lg:w-[10%] md:w-[15%] sm:w-[20%] w-[20%] object-cover"
            style={{ right: "85%", top: "10%" }}
            src={require("../../assets/[H]blob_c_2.png")}
            alt="blob_c"
          />
          <img
            className="absolute lg:w-[10%] md:w-[15%] sm:w-[20%] w-[20%] object-cover"
            style={{ top: "10%", left: "0%" }}
            src={require("../../assets/[H]blob_b.png")}
            alt="blob_c"
          />

          <h1 className="fadein text-snow font-bold">Application</h1>
          <h2 className="fadein underline text-snow font-bold">Spring 2024</h2>
          <div className="text-center lg:w-1/3 md:w-1/2 w-2/3 mt-6 text-snow">
            Applications have closed! Thank you for applying to our club this Spring, and we'll be seeing you in the Fall.
          </div>
          {/* <div className="mt-6 text-snow">Scroll here for more details:</div> */}

          {/* <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            stroke-width="1.5"
            stroke="currentColor"
            class="w-8 h-8 absolute top-3/4 mt-2 hover:cursor-pointer text-white hover:text-lightcoral duration-300"
            onClick={() => {
              setIndex(1);
              console.log(index);
            }}
          >
            <path
              stroke-linecap="round"
              stroke-linejoin="round"
              d="M19.5 13.5L12 21m0 0l-7.5-7.5M12 21V3"
            />
          </svg> */}
        </section>

        {content.map((element) => (
          <SLIDES
            icon={element.icon}
            title={element.title}
            description={element.description}
            text={element.text}
            link={element.link}
            length={element.length}
          />
        ))}
      </animated.div>

      {index === 0 ? (
        ""
      ) : (
        <div className="absolute z-10 md:right-8 right-3 top-1/2 transform -translate-y-1/2 h-1/2 flex flex-col justify-evenly">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            stroke-width="1.5"
            stroke="currentColor"
            class="w-8 h-8 hover:cursor-pointer text-white hover:text-lightcoral duration-300"
            onClick={() => {
              setIndex(Math.max(0, index - 1));
              console.log(index);
            }}
          >
            <path
              stroke-linecap="round"
              stroke-linejoin="round"
              d="M4.5 10.5L12 3m0 0l7.5 7.5M12 3v18"
            />
          </svg>

          <div className="flex flex-col items-center">
            {content.map((_, idx) => (
              <div
                key={idx}
                className={`mt-[20px] first:mt-0 slideshowDot${index - 1 === idx ? " active" : ""}`}
              ></div>
            ))}
          </div>

          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            stroke-width="1.5"
            stroke="currentColor"
            class="w-8 h-8 hover:cursor-pointer text-white hover:text-lightcoral duration-300"
            onClick={() => {
              setIndex(Math.min(index + 1, content.length));
              console.log(index);
            }}
          >
            <path
              stroke-linecap="round"
              stroke-linejoin="round"
              d="M19.5 13.5L12 21m0 0l-7.5-7.5M12 21V3"
            />
          </svg>
        </div>
      )}
    </div>
  );
}
