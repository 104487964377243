import React from 'react'
import { animated, useSpring } from "react-spring"

function LOGO({logo, size, delay, numMembers}) {

  const style = useSpring({
    from: {opacity: 0},
    to: {opacity: 1},
    delay: delay
  })

  let logoSpace;

  if(size > 1){
    logoSpace = "flex justify-center items-center relative group md:mt-[4%]"
  }
  else{
    logoSpace = "basis-1/4 flex justify-center items-center relative group md:mt-[4%]"
  }

  return(
    <animated.div style={style} className={logoSpace}>
      <div className='absolute -top-12 left-1/2 -translate-x-1/2 bg-black rounded-md text-white p-2 opacity-0 group-hover:opacity-100 transition duration-300 whitespace-nowrap overflow-hidden'>
        {numMembers} 
        {
          numMembers > 1 ? " Members" : " Member"
        }
      </div>
      <img src={logo} className={size == 2 ? "3xl:h-16 h-12" : "3xl:h-12 h-8"}/>
    </animated.div>
  )
}

export default LOGO