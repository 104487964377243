// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getAuth } from "firebase/auth";
import { getFirestore } from "firebase/firestore";

// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyCZ5AHHq55f68C7JocHiOjGcWJTTmqI3X8",

  authDomain: "nuft-site.firebaseapp.com",

  databaseURL: "https://nuft-site-default-rtdb.firebaseio.com",

  projectId: "nuft-site",

  storageBucket: "nuft-site.appspot.com",

  messagingSenderId: "1041292274044",

  appId: "1:1041292274044:web:13156b2f0f3d1abbe09cf9",

  measurementId: "G-BXDGS5ZEGK",
};

// Initialize Firebase
// const analytics = getAnalytics(app);
const app = initializeApp(firebaseConfig);
export const auth = getAuth(app);
export const db = getFirestore(app);

export default app;
