import React from "react";
import Linkicon from "../../assets/linkicon.png";
import BUTTON from "../global/BUTTON";

function SLIDES(props) {
  return (
    <section className="flex flex-col justify-center items-center flex-grow h-full w-screen bg-transparent">
      <div className="flex md:flex-row flex-col w-3/4 max-w-[2000px] mx-auto">
        <div className="flex-1 flex justify-center items-center">
          <img src={props.icon} className="w-1/2 h-auto" alt="icon"></img>
        </div>
        <div className="flex-1 md:border-l-2 border-white flex justify-center items-center text-white md:pl-10">
          <div className="flex flex-col items-center md:items-start w-72 md:text-start text-center">
            <h1 className="font-display font-bold md:mb-4 md:mt-0 mt-4 lg:text-3xl text-2xl">
              {props.title}
            </h1>
            <p className="font-body mb-4 md:mt-0 mt-4 lg:text-base text-sm">
              {props.description}
            </p>
            <div className="w-[185px]">
              <BUTTON
                text={props.text}
                icon={Linkicon}
                hovwidth={props.length}
                link={props.link}
              />
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default SLIDES;
