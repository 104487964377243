import React, { useState, useEffect } from "react";
import { confirmAlert } from "react-confirm-alert"; // Import
import "react-confirm-alert/src/react-confirm-alert.css"; // Import css
import { useNavigate } from "react-router-dom";
import { UserAuth } from "../../context/Authcontext";
import { db } from "../../firebase";
import { getAuth, onAuthStateChanged, deleteUser } from "firebase/auth";
import { doc, getDoc, deleteDoc } from "firebase/firestore";

import "react-widgets/styles.css";
import "../../Adminedit.css";
import PERSONCARD from "../../components/member_tracking/PERSONCARD";
import EDITPERSONCARD from "../../components/member_tracking/EDITPERSONCARD";

export default function PROFILE() {
  const { logout } = UserAuth();

  const [uid, setUid] = useState("");

  const [profile, setProfile] = useState(null);
  const navigate = useNavigate();
  const [userRef, setUserRef] = useState();
  const [u, setU] = useState("");

  //const [error, setError] = useState("");

  const handleDelete = async () => {
    confirmAlert({
      title: "Confirm to delete",
      message: "Are you sure you want to delete " + profile.first_name + "?",
      buttons: [
        {
          label: "Yes",
          onClick: () => {
            try {
              deleteDoc(userRef);
              try {
                deleteUser(u);
              } catch (e) {
                console.log(e.message);
              }
              alert("Your profile has been deleted.");
              handleLogout();
            } catch (e) {
              //setError(e.message);
              console.log(e.message);
              alert("Error deleting user.");
            }
          },
        },
        {
          label: "No",
          onClick: () => {
            "";
          },
        },
      ],
    });
  };

  const handleLogout = async () => {
    try {
      await logout();
      navigate("/login");
      console.log("logged out");
    } catch (e) {
      console.log(e.message);
    }
  };

  useEffect(() => {
    const auth = getAuth();
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      if (user) {
        setU(user);
        setUid(user.uid);
        let userRef = doc(db, "users", user.uid);
        setUserRef(userRef);
        const getProfile = async () => {
          console.log("Pulled profile data");
          const response = await getDoc(userRef);
          setProfile(response.data());
        };
        getProfile();
      } else {
        handleLogout();
      }
    });
    return unsubscribe;
  }, []);

  return (
    <div
      className="relative flex flex-col align-center w-full overflow-hidden
    bg-gradient-to-br from-royalblue to-black text-snow"
    >
      {uid && profile ? (
        <div>
          <div className="relative flex flex-row justify-left z-10 p-20 pt-40">
            <div className="flex flex-col w-2/3 text-snow">
              <h1 className="font-bold relative flex flex-row justify-left">
                Welcome, &nbsp;
                {profile.first_name + " " + profile.last_name}
              </h1>

              <h2 className="relative flex flex-row justify-left mb-4">
                Feel free to
                {profile.type === "Applicant"
                  ? " edit and submit your application below, "
                  : " edit your profile, "}{" "}
                contact us, or view relevant resources.
              </h2>

              <div className="flex h-[750px] w-full pr-4">
                <EDITPERSONCARD member={profile} userRef={userRef} />
              </div>
            </div>

            <div className="flex-1 flex flex-col justify-between items-end pt-4 ml-6">
              <div className="flex flex-col items-end">
                <h2
                  className={`w-fit py-1 px-2 mb-2 rounded-lg border-4 font-bold ${
                    profile.type === "Applicant"
                      ? "border-blue-500 bg-blue-500"
                      : ""
                  } ${
                    profile.type === "Admin" ? "border-red-500 bg-red-500" : ""
                  } ${
                    profile.type === "Member"
                      ? "border-emerald-500 bg-emerald-500"
                      : ""
                  } ${
                    profile.type === "Sponsor"
                      ? "border-yellow-500 bg-yellow-500"
                      : ""
                  }`}
                >
                  {profile.type}
                </h2>
                <h1 className="font-bold relative flex flex-row justify-left">
                  LINKS
                </h1>

                {profile.type === "Admin" || profile.type === "Member" ? (
                  <a href="/resources" className="menuitem profile-resources">
                    "RESOURCES"
                  </a>
                ) : (
                  ""
                )}

                {profile.type !== "Admin" ? (
                  <a href="/contact" className="menuitem profile-resources">
                    "CONTACT"
                  </a>
                ) : (
                  ""
                )}

                <a href="/projects" className="menuitem profile-resources">
                  "PROJECTS"
                </a>
                <a href="/technologies" className="menuitem profile-resources">
                  "TECHNOLOGIES"
                </a>

                <a href="/leadership" className="menuitem profile-resources">
                  "LEADERSHIP"
                </a>

                {profile.type === "Applicant" ? (
                  <a
                    href="/application"
                    className="menuitem profile-resources text-right"
                  >
                    "APPLICATION PROCESS"
                  </a>
                ) : (
                  ""
                )}
                {profile.type === "Admin" ||
                profile.type === "Member" ||
                profile.type === "Sponsor" ? (
                  <a href="/members" className="menuitem profile-resources">
                    "DIRECTORY"
                  </a>
                ) : (
                  ""
                )}
                {profile.type === "Admin" ? (
                  <a href="/admin" className="menuitem profile-resources">
                    "ADMIN PAGE"
                  </a>
                ) : (
                  ""
                )}
                {profile.type === "Admin" ? (
                  <a href="/applicants" className="menuitem profile-resources">
                    "APPLICANTS"
                  </a>
                ) : (
                  ""
                )}
              </div>

              <div className="flex flex-col items-end">
                <button
                  className="profile-resources menuitem"
                  onClick={handleLogout}
                >
                  "LOGOUT"
                </button>

                <button
                  className="w-fit mt-4 text-red-600 hover:text-darkorchid"
                  onClick={handleDelete}
                >
                  Delete Profile
                </button>
              </div>
            </div>
          </div>

          <img
            className="fixed z-0 top-0 w-full object-cover h-full"
            src={require("../../assets/[H]bg.png")}
            alt="grid"
          />
          <img
            className="fixed fadein top-0 left-0"
            style={{ height: "2000px" }}
            src={require("../../assets/[c]contact_background.png")}
            alt="gradient"
          />
        </div>
      ) : (
        "Loading..."
      )}
    </div>
  );
}
