import React, { useState } from "react";
import BUTTON from "./BUTTON";
import Linkicon from "../../assets/linkicon.png";
import Menuicon from "../../assets/menuicon.png";
import { UserAuth } from "../../context/Authcontext";

export default function MENU() {
  const { user } = UserAuth();
  function menuCard() {
    var currentpage;
    if (window.location.pathname === "/") {
      currentpage = "HOME";
    } 
    else if (window.location.pathname === "/projects") {
      currentpage = "PROJECTS";
    }
    else if (window.location.pathname === "/leadership") {
      currentpage = "LEADERSHIP";
    } 
    else if (window.location.pathname === "/technologies") {
      currentpage = "TECHNOLOGIES";
    } 
    else if (window.location.pathname === "/contact") {
      currentpage = "CONTACT";
    } 
    else if (window.location.pathname === "/application") {
      currentpage = "APPLICATION";
    } 
    else if (window.location.pathname === "/login") {
      currentpage = "LOGIN";
    } 
    else if (window.location.pathname === "/resources") {
      currentpage = "RESOURCES";
    } 
    else if (window.location.pathname === "/profile") {
      currentpage = "PROFILE";
    } 
    else if (window.location.pathname === "/admin") {
      currentpage = "ADMIN";
    } 
    else if (window.location.pathname === "/adminedit") {
      currentpage = "ADMINEDIT";
    } 
    else if (window.location.pathname === "/signup") {
      currentpage = "SIGNUP";
    }
    else if (window.location.pathname === "/outcomes") {
      currentpage = "OUTCOMES";
    }
    return (
      <div>
        <div
          className="flex flex-col justify-center items-center
                            fixed z-50 w-full h-screen"
        >
          <div>
            <div className="flex flex-row p-5 justify-end">
              <div
                id="x-button"
                className="r-0"
                onClick={() => {
                  setClicked(false);
                }}
              >
                <img
                  className="m-2 flex-none"
                  src={require("../../assets/x.png")}
                  alt="icon"
                />
              </div>
            </div>
            <div
              id="menu"
              className="roboto-mono p-10 flex flex-col items-center"
            >
              <p
                className="relative z-20"
                style={{ fontSize: "calc(14px + 0.3vw)" }}
              >
                <mark className="code-comment">
                  # current page: {currentpage}
                </mark>
                <br />
                <br />
                Pages:
                <br />
                <mark className="flex flex-col">
                  <span className="lg:inline-block flex flex-col ml-36 lg:ml-0">
                    <div className="inline-block">
                      <a href="/" className="menuitem">
                        "HOME"
                      </a>
                      ,
                    </div>
                    {/* <div className="inline-block">
                      <a href="/projects" className="menuitem">
                        "PROJECTS"
                      </a>
                      ,
                    </div> */}
                    <div className="inline-block">
                      <a href="/leadership" className="menuitem">
                        "LEADERSHIP"
                      </a>
                      ,
                    </div>
                    <div className="inline-block">
                      <a href="/outcomes" className="menuitem">
                        "OUTCOMES"
                      </a>
                      ,
                    </div>
                  </span>
                  <span>
                    <div className="inline-block">
                      <a href="/technologies" className="menuitem">
                        "TECHNOLOGIES"
                      </a>
                      ,
                    </div>
                    {/* <div className="inline-block">
                      <a href="/resources" className="menuitem">
                        "RESOURCES"
                      </a>
                      ,
                    </div> */}
                    <div className="inline-block">
                      <a href="/contact" className="menuitem">
                        "CONTACT"
                      </a>
                      ,
                    </div>
                    <div className="inline-block">
                      <a href="/application" className="menuitem">
                        "APPLY"
                      </a>
                    </div>
                  </span>
                  <br />
                  <div className="inline-block montserrat text-sm mt-2">
                    <BUTTON
                      text={user ? "Profile" : "Login"}
                      icon={Linkicon}
                      hovwidth={110}
                      link={"/login"}
                    />
                  </div>
                  <div className="lg:inline-block lg:ml-0 ml-24"></div>
                </mark>
              </p>
            </div>
          </div>
        </div>
        <div className="fixed z-40 w-full h-screen blur"></div>
      </div>
    );
  }
  const [clicked, setClicked] = useState(false);
  return clicked ? (
    menuCard()
  ) : (
    <div
      className="fixed top-6 z-50 right-[5%] text-snow"
      onClick={() => {
        setClicked(true);
      }}
    >
      <BUTTON text={"MENU"} icon={Menuicon} hovwidth={110} />
    </div>
  );
}
