import React from 'react'
import { Field, FieldArray, ErrorMessage } from "formik";

function INFOLIST_L({category, info, objFields, color}) {
  return (
    <>
      <div className="member-info-big profile-margins">
        <div className="member-info-label w-[196px] ">
          {category}
        </div>
        <div className="member-info-line" />
      </div>

      <div className="member-info-margin mt-0 ">
        {info
          ? info.map((element) => {
              return (
                <div className="border-2 rounded-md w-full px-3 py-2 flex flex-row justify-between text-snow text-sm mt-2"
                style={{borderColor: color}}>
                  <div>
                    <div className="text-md font-bold">
                      {element[objFields[0]]}
                    </div>
                    {element[objFields[1]]}
                  </div>

                  {element[objFields[2]]}
                </div>
              );
            })
          : ""}
      </div>
    </>
  )
}

function FIELDLIST_L({category, value, fieldname, objFields, validation, color }) {
  return (
    <>
      <div className="member-info-big profile-margins">
        <div className="member-info-label w-[196px] ">
          {category}
        </div>
        <div className="member-info-line" />
      </div>

      <div className="member-info-margin mt-0 ">

        <FieldArray name={fieldname}>
          {(arrayHelpers) => (
            <div>
              {value.length > 0 &&
                value.map((element, index) => (
                  <div className="flex flex-row">
                    <button
                      type="button"
                      className="secondary m-auto hover:text-red-400 duration-200 pr-2"
                      onClick={() => arrayHelpers.remove(index)}
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke-width="1.5"
                        stroke="currentColor"
                        class="w-6 h-6"
                      >
                        <path
                          stroke-linecap="round"
                          stroke-linejoin="round"
                          d="M6 18L18 6M6 6l12 12"
                        />
                      </svg>
                    </button>
                    <div
                      className="border-2 rounded-md w-full px-3 py-2 flex flex-row justify-between text-purple-400 text-sm mt-2"
                      key={index}
                      style={{borderColor: color}}
                    >
                      <div className="basis-1/3">
                        <div className="text-md font-bold mr-2">
                          <Field
                            id={`${fieldname}.${index}.${objFields[0]}`}
                            name={`${fieldname}.${index}.${objFields[0]}`}
                            placeholder="Company name"
                            className="profile-field hover:text-blue-400 w-fit"
                            validate={validation}
                          />
                          <ErrorMessage
                            name={`${fieldname}.${index}.${objFields[0]}`}
                            component="div"
                            className="field-error"
                          />
                        </div>
                        <Field
                          id={`${fieldname}.${index}.${objFields[1]}`}
                          name={`${fieldname}.${index}.${objFields[1]}`}
                          placeholder="Position"
                          className="profile-field hover:text-blue-400 w-fit"
                          validate={validation}
                        />
                        <ErrorMessage
                          name={`${fieldname}.${index}.${objFields[1]}`}
                          component="div"
                          className="field-error"
                        />
                      </div>

                      <div className="h-fit">
                        <Field
                          id={`${fieldname}.${index}.${objFields[2]}`}
                          name={`${fieldname}.${index}.${objFields[2]}`}
                          placeholder="Year"
                          className="profile-field hover:text-blue-400"
                          validate={validation}
                        />
                        <ErrorMessage
                          name={`${fieldname}.${index}.${objFields[2]}`}
                          component="div"
                          className="field-error"
                        />
                      </div>
                    </div>
                  </div>
                ))}
              <button
                type="button"
                className="secondary mt-2 hover:text-green-400 duration-200"
                onClick={() => {
                  arrayHelpers.push("");
                }}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke-width="1.5"
                  stroke="currentColor"
                  class="w-6 h-6"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    d="M12 4.5v15m7.5-7.5h-15"
                  />
                </svg>
              </button>
            </div>
          )}
        </FieldArray>
      </div>
    </>
  )
}

export {INFOLIST_L, FIELDLIST_L}