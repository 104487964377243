import {React, useState} from 'react'
import { useSwipeable } from "react-swipeable";
import LOGO from "./LOGO"
import { animated, useSpring } from "react-spring"

function MOBILEDISPLAY({feature, company, assetPrefix}){
  let i = 0
  const grouped = company.reduce((dict, item) => {
    const rownum = Math.floor(i / 4)
    i += 1
    return {
      ...dict,
      [rownum]: [
        ...(dict[rownum] || []),
        item,
      ],
    }
  }, {})
  const [index, setIndex] = useState(0);

  const springstyle = useSpring({
    transform: `translateX(-${index * 20}%)`
  });

  const handler = useSwipeable({
    onSwipedRight: () => {
      setIndex(Math.max(0, index - 1))
    },
    onSwipedLeft: () => {
      setIndex(Math.min(index + 1, Object.keys(grouped).length))
    },
    swipeDuration: 500,
    preventScrollOnSwipe: true,
    trackMouse: true
  })
  
  return (
    <>
      <div className='w-screen flex-grow overflow-x-hidden'
        {...handler}>
        <div className='w-screen h-full flex flex-nowrap min-w-fit whitespace-nowrap relative'>
          <animated.div className='flex flex-row h-full absolute'
            style={springstyle}>
            <div className='w-screen flex flex-col justify-around items-center px-8'>
              {
                feature.map((obj) => <LOGO logo={assetPrefix + obj.logo} size={2} delay={400} numMembers={obj.numMembers}/>)
              }
            </div>
            
            {
              Object.keys(grouped).map((key) => {
                return (
                  <div className='w-screen flex flex-col justify-around items-center'>
                    {
                      grouped[key].map((obj) => {
                        const delay = 700
                        return (
                          <LOGO logo={assetPrefix + obj.logo} size={1} delay={delay} numMembers={obj.numMembers}/>
                        )
                      })
                    }
                  </div>
                )
              })
            }
          </animated.div>
        </div>
      </div>
      <div className='w-full h-10 flex flex-row items-center justify-center'>
        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="#c4c4c4" class="w-6 h-6"
        onClick={() => {setIndex(Math.max(0, index - 1))}}>
          <path stroke-linecap="round" stroke-linejoin="round" d="M10.5 19.5 3 12m0 0 7.5-7.5M3 12h18" />
        </svg>

        {
          [...Array(Object.keys(grouped).length + 1).keys()].map((idx) => (
            <div
              key={idx}
              className={`ml-2 first:ml-0 slideshowDot${index === idx ? " activeButCooler" : ""}`}
            ></div>
          ))
        }

        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="#c4c4c4" class="w-6 h-6 ml-2"
        onClick={() => {setIndex(Math.min(index + 1, Object.keys(grouped).length))}}>
          <path stroke-linecap="round" stroke-linejoin="round" d="M13.5 4.5 21 12m0 0-7.5 7.5M21 12H3" />
        </svg>

      </div>
    </>
  )
}

export default MOBILEDISPLAY