import React, { useState } from "react";
import SLIDES from "../../components/home_page/SLIDES";

import market_pic from "../../assets/stock-market.png";
import class_pic from "../../assets/classroom.png";
import program_pic from "../../assets/programming.png";
import server_pic from "../../assets/data-storage.png";
import data_pic from "../../assets/data-science.png";
import cpu_pic from "../../assets/cpu.png";
import { useSpring, animated } from "react-spring";

export default function RESOURCES() {
  const [index, setIndex] = useState(0);

  const springstyle = useSpring({
    transform: `translateY(-${index * 100}%)`,
  });

  const colorspring = useSpring({
    backgroundColor: `rgba(0, 0, 0, ${(index - 1) * 0.33})`,
  });

  let content = [
    {
      length: 180,
      link: "https://www.overleaf.com/read/kpdytnncnrcb",
      text: "View Guide",
      icon: class_pic,
      title: "Course Guide",
      description:
        "This piece is really here for people who have goals that require significant academic planning, decision-making, and structure. Obviously, this will be aimed primarily at students interested in Quant Finance to some degree, but an effort will be made to highlight paths in ML and software engineering.",
    },
    {
      length: 180,
      link: "https://github.com/northwesternfintech/2024QuantInternships",
      text: "View the List",
      icon: market_pic,
      title: "2024 Quant Internship List",
      description:
        "This is a list of all the companies that have posted internships for the summer of 2024. It is updated daily, and will be maintained until the end of the summer. It is a great resource for people who want to get a head start on their internship search, or who want to see what companies are hiring for the summer of 2024.",
    },
    {
      length: 180,
      link: "https://github.com/echavemann/cplib",
      text: "View CPLIB",
      icon: program_pic,
      title: "Competitive Programming Template + Guide",
      description:
        "This is a template that can be used for competitive programming or cooking on code-chef. It is a great resource for people who want to develop their programming skills, or who want to get started on competitive programming.",
    },
    {
      length: 385,
      text: "fintechclub.ece.northwestern.edu",
      icon: server_pic,
      title: "NUFT Server Access",
      description:
        "This is a guide on how to access the NUFT server living at Northwestern. It is a good resource for people who want to run tasks on a server, or who want to learn how to use a server. SSH to:",
    },
    {
      length: 180,
      text: "View Data",
      link: "https://github.com/northwesternfintech/raccoon",
      icon: data_pic,
      title: "Orderbook Data",
      description:
        "This is a guide on how to access the orderbook data that NUFT has collected. It is a good resource for people who want to learn how to use orderbook data, or who want to learn how to use the data that NUFT has collected.",
    },
    {
      length: 180,
      text: "View Guide",
      link: "https://github.com/echavemann/whatiuse",
      icon: cpu_pic,
      title: "Setup/Gear Guide",
      description:
        "Put together by Ethan, this is a guide on how to setup your computer for development. It is a good resource for people who want to learn how to setup their computer effectively, or curious about what gear NUFT members use.",
    },
  ];

  const colorStep = 1 / (content.length + 1)
  const colorResources = (i) => `rgba(${140 * (i + 1) * colorStep}, ${63 * (i + 1) * colorStep}, ${208 * (i + 1) * colorStep})`;

  return (
    <div className="flex-grow flex flex-col relative bg-[#8d3fe0]">

      <animated.div
        className="absolute z-10 h-screen w-full bg-blend-screen"
        style={colorspring}
      >
        <img
          className="absolute top-0 left-0 w-full min-h-screen"
          src={require("../../assets/[p]slide_background.png")}
          alt="slidebg"
        />
      </animated.div>

      <div className="z-10">
        <section
          id="intro"
          className="flex flex-col justify-center items-center h-screen bg-white"
        >
          <h1 className="fadein text-royalblue font-bold">Resources</h1>
          <div className="text-center lg:w-1/3 md:w-1/2 w-2/3 mt-6 text-royalblue">
            Our team has compiled a list of resources that we have found useful
            in suppoting our members' learning and development. We hope that
            these resources will be useful to you as well.
          </div>
        </section>

        {content.map((element, index) => (
          <div className="h-screen" style={{ 'background-color': colorResources(index) }}>
            <SLIDES
              icon={element.icon}
              title={element.title}
              description={element.description}
              text={element.text}
              link={element.link}
              length={element.length}
            />
          </div>
        ))}
      </div>
    </div>
  );
}
