import React from 'react'
import BUTTON from '../../global/BUTTON'
import { Field } from 'formik'

function HREFINFO({text, icon, hovwidth, link}) {
  return (
    <BUTTON
      text={text}
      icon={icon}
      hovwidth={hovwidth}
      link={link}
      className={"profile-margins"}
      newtab={true}
    />
  )
}

function HREFFIELD({value, fieldname, icon}){
  return (
    <div className="flex flex-row items-center profile-margins">
    <BUTTON icon={icon} link={value} newtab={true}/>
    <Field
      id={fieldname}
      name={fieldname}
      className="profile-field w-full hover:text-blue-400"
    />
  </div>
  )
}

export {HREFFIELD, HREFINFO}