import "../../App.css";
import FORM from "./components/FORM.js";

export default function CONTACT() {
  return (
    <div
      className="relative flex flex-col align-center w-full overflow-hidden
    bg-gradient-to-br from-royalblue to-black text-snow"
    >
      <div className="relative flex flex-row justify-between z-10 p-20">
        <div className="flex flex-col w-1/2 mt-[80px] text-snow">
          <h1 className="font-bold">CONTACT US</h1>
          <h6>
            Fill out the form, and our team will get back to you within 24
            hours.
          </h6>
        </div>
        <FORM />
      </div>
      <img
        className="fixed z-0 top-0 w-full object-cover h-full"
        src={require("../../assets/[H]bg.png")}
        alt="grid"
      />
      <img
        className="fixed fadein top-0 left-0"
        style={{ height: "2000px" }}
        src={require("../../assets/[c]contact_background.png")}
        alt="gradient"
      />
      <img
        className="fixed fadein top-0 left-0 w-full"
        style={{ height: "1000px" }}
        src={require("../../assets/[H]blob_d.png")}
        alt="gradient"
      />
    </div>
  );
}
