import React from "react";
import Linkedinicon from "../../../assets/linkedin_icon.png";
import Githubicon from "../../../assets/githubicon.png";
import BUTTON from "../../../components/global/BUTTON";

export default function CARD(props) {
  return (
    <div
      className="z-20 bg-black rounded-xl overflow-hidden shadow-lg m-4 drop-shadow-2xl
                        lg:w-[20vw] md:w-[40vw] sm:w-[45vw] w-[75vw] max-w-[600px]"
    >
      <img
        className="fadein object-cover w-11/12 h-[350px] card-center rounded-lg pt-2"
        src={props.photo}
        alt={props.name}
      />
      <div className="px-6 py-4 my-4 flex flex-col items-center">
        <div className="font-bold text-xl">{props.name}</div>
        <div className="text-lg m-1 text-gray-70">
          <em>{props.title}</em>
        </div>
        <div className="animated-divider"></div>
        <p className="card-text-height text-base w-11/12 mb-12">{props.text}</p>
        <div
          id="links"
          className="absolute flex flex-row justify-around w-full bottom-0 mb-6"
        >
          <BUTTON
            text={"LinkedIn"}
            icon={Linkedinicon}
            hovwidth={135}
            link={props.linkedin}
          />
          <BUTTON
            text={"GitHub"}
            icon={Githubicon}
            hovwidth={115}
            link={props.github}
          />
        </div>
      </div>
    </div>
  );
}
