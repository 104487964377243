import React, { useState, useCallback, useRef } from "react";
import SLIDES from "../../components/home_page/SLIDES";

import core_pic from "../../assets/server-storage.png";
import short_pic from "../../assets/data-analytics.png";
import dl_pic from "../../assets/deep-learning.png";
import rl_pic from "../../assets/coding.png";
import { useSpring, animated } from "react-spring";
import { useSwipeable } from "react-swipeable";

export default function PROJECTS() {
  const [index, setIndex] = useState(0);

  const springstyle = useSpring({
    transform: `translateY(-${index * 100}%)`,
  });

  const colorspring = useSpring({
    backgroundColor: `rgba(0, 0, 0, ${(index - 1) * 0.33})`,
  });

  let content = [
    {
      length: 180,
      text: "Apply Now",
      link: "/login",
      icon: core_pic,
      title: "Core Software",
      description:
        "NUFT's two core teams deal with low-level software generation and systems work, using C++ to maintain, expand, and accelerate our trading platform and predicting engine. Prospective members should be well versed in C++ and algorithm design, as well as have some experience and coursework in Computer Systems.",
    },
    {
      length: 180,
      text: "Apply Now",
      link: "/login",
      icon: short_pic,
      title: "Short Horizons",
      description:
        "Short Horizons deals with prediction windows under 30 seconds, working extensively with the Orderbook and real time data to orchestrate NUFT's lowest turnover activities. The application process is varied, ranging from creation of performant systems to basic statistical learning. ",
    },
    {
      length: 180,
      text: "Apply Now",
      link: "/login",
      icon: dl_pic,
      title: "Deep Learning",
      description:
        "Most of NUFT's most powerful features and longest horizon forecasts come from the DL team. This team produces a large variety of models, ranging from complex feature models for other teams to their very own time series forecasting. Members get scheduling priority on NUFT's servers like unrestricted job runtime on the latest 4090 servers. Prospective members should have taken CS449 and/or have some experience with Kaggle at the least. ",
    },
    {
      length: 180,
      text: "Apply Now",
      link: "/login",
      icon: rl_pic,
      title: "Reinforcement Learning",
      description:
        "RL specializes in improving existing strategies and producing unique features. Collaborating with other project teams, examples of RL outcomes include order placement optimization in short horizons, NUFT’s internal AutoTrader project, and applying internally implemented SOTA algorithms. Applicants should be motivated and able to learn quickly in order to work in the team's fast-paced environment.",
    },
  ];

  var lastAnimation = 0;
  var animationTime = 200; // in ms
  var quietPeriod = 500; // in ms, time after animation to ignore mousescroll

  const onWheel = useCallback(
    (e) => {
      e.preventDefault();
      e.stopPropagation();
      var timeNow = new Date().getTime();

      // change this to deltaX/deltaY depending on which
      // scrolling dir you want to capture
      const deltaOfInterest = e.deltaY;
  
      if (deltaOfInterest == 0) {
          // Uncomment if you want to use deltaX
          return;
      }
  
      // Cancel scroll if currently animating or within quiet period
      if(timeNow - lastAnimation < quietPeriod + animationTime) {
          return;
      }
  
      if (deltaOfInterest < 0) {
        setIndex(prev => {
          return Math.max(0, prev - 1)
        })
        lastAnimation = timeNow;
      } 
      else {
        setIndex(prev => {
          return Math.min(prev + 1, content.length)
        })
        lastAnimation = timeNow;
      }
    },
    [],
  );

  // Thank you my god stack overflow https://stackoverflow.com/questions/63663025/react-onwheel-handler-cant-preventdefault-because-its-a-passive-event-listenev
  const divRef = useRef(null);
  const divRefCallback = useCallback(
    (node) => {
      if (node == null) {
        if (divRef.current != null) {
          /* This happens when the <div> component tries to detach the old divRefCallback.
           * Since we are inside a `useCallback`, the `onWheel` callback being remove is still the old one here.
           */
          divRef.current.removeEventListener('wheel', onWheel, { passive: false });
        }
        return;
      }
      divRef.current = node;
      node.addEventListener('wheel', onWheel, { passive: false });
    },
    [onWheel],
  );

  const handler = useSwipeable({
    onSwipedUp: () => {
      setIndex(Math.min(index + 1, content.length))
      console.log("swiped up")
    },
    onSwipedDown: () => {
      setIndex(Math.max(0, index - 1))
      console.log("swiped down")
    },
    swipeDuration: 500,
    preventScrollOnSwipe: true,
    trackMouse: true
  })

  return (
    <div className="flex-grow flex flex-col relative overflow-hidden bg-[#8d3fe0] overscroll-contain"
      {...handler}
      ref={divRefCallback}
      >
      <animated.div
        className="absolute z-10 h-screen w-full bg-blend-screen"
        style={colorspring}
      >
        <img
          className="absolute top-0 left-0 w-full min-h-screen"
          src={require("../../assets/[p]slide_background.png")}
          alt="slidebg"
        />
      </animated.div>

      <animated.div className="absolute z-10 h-full" style={springstyle}>
        <section
          id="intro"
          className="flex flex-col justify-center items-center h-full bg-white"
        >
          <h1 className="fadein text-royalblue font-bold">PROJECTS</h1>
          <div className="text-center lg:w-1/3 md:w-1/2 w-2/3 mt-6 text-royalblue">
            Our project teams are modeled off of the same work structure that
            drives the world's biggest tech companies, and members are free to
            select their teams and choose what they work on within the NUFT
            projects.
          </div>
          <div className="mt-6 text-royalblue">Click here to learn more:</div>

          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            stroke-width="1.5"
            stroke="royalblue"
            class="w-8 h-8 absolute top-3/4 hover:cursor-pointer"
            onClick={() => {
              setIndex(1);
              console.log(index);
            }}
          >
            <path
              stroke-linecap="round"
              stroke-linejoin="round"
              d="M19.5 13.5L12 21m0 0l-7.5-7.5M12 21V3"
            />
          </svg>
        </section>

        {content.map((element) => (
          <SLIDES
            icon={element.icon}
            title={element.title}
            description={element.description}
            link={element.link}
            text={element.text}
            length={element.length}
          />
        ))}
      </animated.div>

      {index === 0 ? (
        ""
      ) : (
        <div className="absolute z-10 md:right-8 right-3 top-1/2 transform -translate-y-1/2 h-1/2 flex flex-col justify-evenly">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            stroke-width="1.5"
            stroke="currentColor"
            class="w-8 h-8 hover:cursor-pointer text-white hover:text-lightcoral duration-300"
            onClick={() => {
              setIndex(Math.max(0, index - 1));
              console.log(index);
            }}
          >
            <path
              stroke-linecap="round"
              stroke-linejoin="round"
              d="M4.5 10.5L12 3m0 0l7.5 7.5M12 3v18"
            />
          </svg>

          <div className="flex flex-col items-center">
            {content.map((_, idx) => (
              <div
                key={idx}
                className={`mt-[20px] first:mt-0 slideshowDot${index - 1 === idx ? " active" : ""}`}
              ></div>
            ))}
          </div>

          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            stroke-width="1.5"
            stroke="currentColor"
            class="w-8 h-8 hover:cursor-pointer text-white hover:text-lightcoral duration-300"
            onClick={() => {
              setIndex(Math.min(index + 1, content.length));
              console.log(index);
            }}
          >
            <path
              stroke-linecap="round"
              stroke-linejoin="round"
              d="M19.5 13.5L12 21m0 0l-7.5-7.5M12 21V3"
            />
          </svg>
        </div>
      )}
    </div>
  );
}
