import React, { useRef, useEffect } from "react";
import Linkicon from "../../assets/linkicon.png";
import BUTTON from "../../components/global/BUTTON";
import { useNavigate } from "react-router-dom";
import { UserAuth } from "../../context/Authcontext";

import { useState } from "react";

export default function FORM() {
  const { user } = UserAuth();
  const navigate = useNavigate();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState("");
  const { signIn } = UserAuth();
  const form = useRef();

  if (user) {
    navigate("/profile", {
      state: { uid: user.uid },
    });
  }

  const onLogin = async (e) => {
    e.preventDefault();
    setError("");
    try {
      await signIn(email, password);
    } catch (e) {
      setError(e.message);
      console.log(e.message);
    }
  };

  return (
    <div>
      <form
        className="ml-[100px] mt-[80px] max-w-[2000px] mx-auto"
        ref={form}
        onSubmit={onLogin}
      >
        <label>
          <h4 className="mt-[20px]">Email</h4>
        </label>
        <input
          type="email"
          required
          label="Email address"
          className="block w-[100%] bg-transparent text-[14px] font-snow
                   border-b-2 p-[10px]"
          style={{ animationName: "expand", animationDuration: "500ms" }}
          onChange={(event) => setEmail(event.target.value)}
          value={email}
          placeholder="Email address"
        />
        <label>
          <h4 className="mt-[20px]">Password</h4>
        </label>
        <input
          type="password"
          required
          label="Create password"
          className="block w-[100%] bg-transparent text-[14px] font-snow
                   border-b-2 p-[10px]"
          style={{ animationName: "expand", animationDuration: "500ms" }}
          onChange={(event) => setPassword(event.target.value)}
          value={password}
          placeholder="Password"
        />
        <div className="mt-4">
          <a
            href="/passwordreset"
            className="text-sm text-snow hover:text-blue-500"
          >
            Forgot password?
          </a>
        </div>
        {/* <div className="mt-[20px]">
          <BUTTON
            text={"Not a member yet? Sign up"}
            icon={Linkicon}
            hovwidth={340}
            link={"/signup"}
          />
        </div> */}

        <div className="mt-[20px]">
          <BUTTON
            text={"Applications have closed..."}
            icon={Linkicon}
            hovwidth={340}
            link={"/application"}
          />
        </div>

        <div className="mt-[20px]">
          <button type="submit">
            <BUTTON text={"Log In"} icon={Linkicon} hovwidth={128} />
          </button>
        </div>
      </form>
    </div>
  );
}
